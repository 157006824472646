import {Box, Grid, Typography} from "@mui/material";
import React from "react";

function PromoPopUp({setShowPromoPopUp}) {
    return (
        <Box
            sx={{
                background: "white",
                zIndex: 5,
                maxWidth: "1000px",
                paddingLeft: "10px",
                paddingRight: "10px",
                borderRadius: "22px",
                border: "1px solid",
                borderColor: "black",
                paddingBottom: "10px",
                height: "auto",
                position: 'relative'
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '5px',
                    right: '5px'
                }}
            >
                <img
                    onClick={() => setShowPromoPopUp(false)}
                    style={{ cursor: "pointer", rotate: "45deg" }}
                    src={require("../../assets/red_plus.png")}
                    alt="arrow_close"
                />
            </Box>
            <Grid
                container
                sx={{
                    display: 'flex',
                    justifyContent: "center",
                }}
            >
                <Grid
                    item
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "10px",
                        width: {
                            xs: '283px',
                            sm: '400px'
                        }
                    }}
                >
                    <Box
                        component="img"
                        sx={{
                            width: {
                                xs: '200px',
                                md: '400px'
                            },
                            height: {
                                xs: '200px',
                                md: '400px'
                            },
                        }}
                        src={require("../../assets/promo/halloweenska-zlava.webp")}
                        alt="halloween_promo"
                    />
                </Grid>
                <Grid
                    item
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "23px",
                        width: {
                            xs: '340px',
                            sm: '400px'
                        }
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: "VisbyCF-Medium",
                            fontSize: 22,
                            lineHeight: "23px",
                            color: "#1b144a",
                            textAlign: "center",
                            marginRight: "25px",
                            marginLeft: "25px",
                        }}
                    >
                        <span style={{fontFamily: "VisbyCF-Bold"}}>Využite strašidelnú zľavu 10%</span> na naše školenia ISTQB CTFL, ISTQB CTFL-AT a SQL!
                        <br/>
                        <br/>
                        Zadajte kód <span style={{fontFamily: "VisbyCF-Bold"}}>HALLOWEEN10</span> pri nákupe a posuňte svoje znalosti na vyššiu úroveň.
                        <br/>
                        <br/>
                        Ponuka platí len do konca októbra.
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
}

export default PromoPopUp