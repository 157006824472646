import { useMemo } from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";

export default function Home() {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_KEY,
  });

  if (!isLoaded) return <div>Loading...</div>;
  if (loadError) {
    return <div>Error loading Google Maps</div>;
  }
  return <Map />;
}

function Map() {
  const center = useMemo(
    () => ({ lat: 48.14506648591806, lng: 17.143156557302575 }),
    []
  );

  return (
    <GoogleMap
      zoom={15}
      center={center}
      mapContainerStyle={{ height: "100%", width: "100%" }}
    >
      <MarkerF position={center} />
    </GoogleMap>
  );
}
