import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm, ValidationError } from "@formspree/react";
import axios from "axios";
import {
  TextField,
  Box,
  Typography,
  Paper,
  Button,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  RadioGroup,
  Radio,
  FormLabel,
  Grid,
} from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import "./formCourse.css";
import CircularProgress from "@mui/material/CircularProgress";

const Form = ({ datesArray, onClose, isSelectedDate = "", courseName, terms, courseId, certification }) => {
  const [SubmitDisabled, setSubmitDisabled] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [errorModal, setErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [discountMessage, setDiscountMessage] = useState("");
  let navigate = useNavigate();
  let availableDates = [];
  for (let i = 0; i < datesArray.length; i++) {
    if (new Date(datesArray[i]) > new Date()) {
      availableDates.push(datesArray[i]);
    }
  }

  const SITE_KEY = process.env.REACT_APP_SITE_KEY;
  const recaptchaRef = useRef(null);

  const handleExpiredToken = () => {
    let token = recaptchaRef.current.getValue();
    if (token) {
      setSubmitDisabled(false);
    } else setSubmitDisabled(true);
  };

  // format date
  const formatDate = (data) => {
    const date = new Date(data); // The Date object you want to convert
    const options = { day: "2-digit", month: "2-digit", year: "numeric" };
    const formattedDate = date.toLocaleDateString("sk-SK", options);
    return formattedDate;
  };

  // useEffect(() => {});

  const textStyle = {
    "& .MuiOutlinedInput-root": {
      "& input": {
        // padding: "10px",
        // fontSize: "14px", // Font size for the input text
        fontFamily: "VisbyCF-Medium", // Font family for the input text
      },
      "& input::placeholder": {
        // fontSize: "14px", // Font size for the placeholder text
        fontFamily: "VisbyCF-Medium", // Font family for the placeholder text
      },
    },
    "& .MuiInputLabel-root": {
      typography: {
        // fontSize: "14px", // Font size for the label
        fontFamily: "VisbyCF-Medium", // Font family for the placeholder text
      },
    },
  };

  const checkBox = {
    height: "20px",
    "& .MuiTypography-root": {
      fontFamily: "VisbyCF-Medium",
      fontSize: 14,
    },
  };

  const [sukromnaOsoba, setSukromnaOsoba] = useState(true);
  const [zaujemOCertifikaciu, setZaujemOCertifikaciu] = useState(false);
  const [suhlasObchodnePodmienky, setObchodnePodmienky] = useState(false);
  const [suhlasPoskytnutieUdajov, setSuhlasPoskytnutieUdajov] = useState(false);
  const [suhlasZnizenieLehotyNaOdstupenie, setSuhlasZnizenieLehotyNaOdstupenie] = useState(false);
  const [platbaTyp, setPlatbaTyp] = useState("faktura");
  const [isDiscountValid, setIsDiscountValid] = useState(false);
  const [formValues, setFormValues] = useState({
    menoPriezvisko: "",
    sukromnaOsoba: true,
    ulicaPopisneCislo: "",
    mesto: "",
    psc: "",
    spolocnost: "",
    ico: "",
    email: "",
    telefon: "",
    datum: isSelectedDate,
    pocetUcastnikov: 1,
    zlavovyKod: "",
    poznamka: `Predbežne objednávam školenie "${courseName}"`,
    zaujemOCertifikaciu: false,
    datumNarodenia: null,
    rodneCislo: "",
    suhlasObchodnePodmienky: false,
    suhlasZnizenieLehotyNaOdstupenie: false,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSukromnaOsobaChange = (e) => {
    setSukromnaOsoba(e.target.checked);
  };

  const handleZaujemOCertifikaciuChange = (e) => {
    setZaujemOCertifikaciu(e.target.checked);
    setFormValues((prevValues) => ({
      ...prevValues,
      ["datumNarodenia"]: null,
    }));
  };
  const handleSuhlasObchodnePodmienkyChange = (e) => {
    setObchodnePodmienky(e.target.checked);
  };

  const handleSuhlasPoskytnutieUdajov = (e) => {
    setSuhlasPoskytnutieUdajov(e.target.checked);
  };

  const handleSuhlasZnizenieLehotyNaOdstupenie = (e) => {
    setSuhlasZnizenieLehotyNaOdstupenie(e.target.checked);
  };

  const handlePlatbaTypChange = (e) => {
    setPlatbaTyp(e.target.value);
  };

  // const [state, handleSubmit] = useForm("mzbqqned");

  const handleSubmit = async (e) => {
    e.preventDefault();
    let trainingId;
    setLoading(true);
    setSubmitDisabled(true);
    if (terms.length > 0) {
      trainingId = terms.find((obj) => obj.date_from === formValues.datum).id;
    }
    const paymentType = () => {
      if (platbaTyp === "faktura") return "invoice";
      if (platbaTyp === "platobna-brana") return "card_payment";
      if (platbaTyp === "kompas") return "kompas";
      if (platbaTyp === "nestrat-pracu") return "nestrat_pracu";
      if (platbaTyp === "pozicka") return "loan";
    };

    const requestBody = sukromnaOsoba
      ? {
          training_term_id: trainingId,
          contact_name: formValues.menoPriezvisko,
          email: formValues.email,
          phone: formValues.telefon,
          no_participants: formValues.pocetUcastnikov,
          payment_type: paymentType(),
          certification: zaujemOCertifikaciu ? "t" : "f",
          note: formValues.poznamka,
          street: formValues.ulicaPopisneCislo,
          city: formValues.mesto,
          psc: formValues.psc,
          rc: formValues.rodneCislo ? formValues.rodneCislo : null,
          discount_code: formValues.zlavovyKod ? formValues.zlavovyKod : null,
          birth_date: formValues.datumNarodenia ? formValues.datumNarodenia : null,
          g_recaptcha_response: recaptchaRef.current.getValue(),
        }
      : {
          training_term_id: trainingId,
          contact_name: formValues.menoPriezvisko,
          email: formValues.email,
          phone: formValues.telefon,
          no_participants: formValues.pocetUcastnikov,
          payment_type: paymentType(),
          certification: zaujemOCertifikaciu ? "t" : "f",
          note: formValues.poznamka,
          street: null,
          city: null,
          psc: null,
          rc: formValues.rodneCislo ? formValues.rodneCislo : null,
          ico: formValues.ico,
          company_name: formValues.spolocnost,
          discount_code: formValues.zlavovyKod ? formValues.zlavovyKod : null,
          birth_date: formValues.datumNarodenia ? formValues.datumNarodenia : null,
          g_recaptcha_response: recaptchaRef.current.getValue(),
        };

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}training_orders`, requestBody);
      const data = response.data;
      setSubmitted(true);
      setLoading(false);
      setSubmitDisabled(false);
      navigate("/congratulation");
    } catch (error) {
      setErrorMessage(error.response.data.message);
      setErrorModal(true);
      setLoading(false);
      setSubmitDisabled(false);
    }
  };

  const isDateWithin14Days = (dateString) => {
    if (!dateString) {
      return false;
    }

    const compareDate = new Date(dateString);
    const currentDate = new Date();

    const futureDate = new Date(currentDate);
    futureDate.setDate(currentDate.getDate() + 14);

    currentDate.setHours(0, 0, 0, 0);
    futureDate.setHours(0, 0, 0, 0);
    compareDate.setHours(0, 0, 0, 0);

    return compareDate <= futureDate;
  }

  // checking DISCOUNT code
  useEffect(() => {
    if (formValues.zlavovyKod === "" && !zaujemOCertifikaciu) {
      return setDiscountMessage("");
    }

    const delay = setTimeout(async () => {
      try {
        const requestBody = {
          training_id: parseInt(courseId),
          discount_code: formValues.zlavovyKod ? formValues.zlavovyKod : null,
          certification: zaujemOCertifikaciu ? "t" : "f",
          no_participants: formValues.pocetUcastnikov,
        };
        // console.log("req bod", requestBody);
        const response = await axios.post(`${process.env.REACT_APP_API_URL}get_training_price`, requestBody);
        const data = response.data;
        // console.log("getting here to discount msg", response);
        if (data.error) {
          setDiscountMessage(data.error);
          setIsDiscountValid(false);
        }
        if (data.price_text) {
          setIsDiscountValid(true);
          setDiscountMessage(data.price_text);
        }
      } catch (error) {
        console.log(error.error);
      }
    }, 1000); // Adjust the delay as per your requirement (e.g., 500ms)

    return () => {
      clearTimeout(delay); // Clear the timeout when the component unmounts or when inputValue changes
    };
  }, [formValues.zlavovyKod, zaujemOCertifikaciu, formValues.pocetUcastnikov]);

  return (
    <Box sx={{ display: "flex" }}>
      {!submitted && !errorModal && (
        <Box
          sx={{
            background: "white",
            zIndex: 5,
            maxWidth: "300px",
            paddingLeft: "20px",
            paddingRight: "20px",
            borderRadius: "22px",
            border: "1px solid",
            borderColor: "black",
            paddingBottom: "20px",
          }}
        >
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "end",
                marginTop: "10px",
              }}
            >
              <img onClick={onClose} style={{ cursor: "pointer", rotate: "45deg" }} src={require("../../assets/red_plus.png")} alt="arrow_close" />
            </Box>
            <TextField
              sx={textStyle}
              id="meno-priezvisko"
              label="Meno a Priezvisko"
              placeholder="Meno a Priezvisko"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              name="menoPriezvisko"
              value={formValues.menoPriezvisko}
              onChange={handleInputChange}
              inputProps={{
                maxLength: 30, // Maximum character limit
              }}
            />

            <FormControlLabel
              control={<Checkbox checked={sukromnaOsoba} onChange={handleSukromnaOsobaChange} name="sukromnaOsoba" />}
              label="Súkromná osoba"
              sx={checkBox}
            />

            {sukromnaOsoba ? (
              <>
                <TextField
                  sx={textStyle}
                  id="ulica-popisne-cislo"
                  label="Ulica a popisné číslo"
                  placeholder="Ulica a popisné číslo"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  required
                  name="ulicaPopisneCislo"
                  value={formValues.ulicaPopisneCislo}
                  onChange={handleInputChange}
                  inputProps={{
                    maxLength: 50, // Maximum character limit
                  }}
                />

                <TextField
                  sx={textStyle}
                  id="mesto"
                  label="Mesto"
                  placeholder="Mesto"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  required
                  name="mesto"
                  value={formValues.mesto}
                  onChange={handleInputChange}
                  inputProps={{
                    maxLength: 30, // Maximum character limit
                  }}
                />

                <TextField
                  sx={textStyle}
                  id="psc"
                  label="PSČ"
                  placeholder="PSČ"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  required
                  name="psc"
                  value={formValues.psc}
                  onChange={handleInputChange}
                  inputProps={{
                    maxLength: 6, // Maximum character limit
                  }}
                />
              </>
            ) : (
              <>
                <TextField
                  sx={textStyle}
                  id="spolocnost"
                  label="Spoločnosť"
                  placeholder="Spoločnosť"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  required
                  name="spolocnost"
                  value={formValues.spolocnost}
                  onChange={handleInputChange}
                  inputProps={{
                    maxLength: 50, // Maximum character limit
                  }}
                />

                <TextField
                  sx={textStyle}
                  id="ico"
                  label="IČO"
                  placeholder="IČO"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  required
                  name="ico"
                  value={formValues.ico}
                  onChange={handleInputChange}
                  inputProps={{
                    maxLength: 12, // Maximum character limit
                  }}
                />
              </>
            )}

            <TextField
              sx={textStyle}
              id="email"
              label="E-mail"
              placeholder="E-mail"
              type="email"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              name="email"
              value={formValues.email}
              onChange={handleInputChange}
              inputProps={{
                maxLength: 50, // Maximum character limit
              }}
            />

            <TextField
              sx={textStyle}
              id="telefon"
              label="Telefón"
              placeholder="Telefón"
              variant="outlined"
              fullWidth
              margin="normal"
              required
              name="telefon"
              value={formValues.telefon}
              onChange={handleInputChange}
              inputProps={{
                maxLength: 15, // Maximum character limit
              }}
            />

            {datesArray.length > 0 && (
              <FormControl fullWidth margin="normal">
                <InputLabel sx={{ fontSize: "16px", fontFamily: "VisbyCF-Medium" }} id="datum-label">
                  Dátum
                </InputLabel>
                <Select labelId="datum-label" id="datum" required label="Dátum" name="datum" value={formValues.datum} onChange={handleInputChange}>
                  {availableDates.map((date) => (
                    <MenuItem key={date} value={date}>
                      {formatDate(date)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            <TextField
              sx={textStyle}
              id="pocet-ucastnikov"
              label="Počet účastníkov"
              placeholder="Počet účastníkov"
              type="number"
              inputProps={{ min: 1 }}
              variant="outlined"
              fullWidth
              margin="normal"
              required
              name="pocetUcastnikov"
              value={formValues.pocetUcastnikov}
              onChange={handleInputChange}
            />

            <TextField
              sx={textStyle}
              id="zlavovy-kod"
              label="Zľavový kód"
              placeholder="Zľavový kód"
              variant="outlined"
              fullWidth
              margin="normal"
              name="zlavovyKod"
              value={formValues.zlavovyKod}
              onChange={handleInputChange}
              inputProps={{
                maxLength: 15,
              }}
            />

            {discountMessage && (
              <Box>
                <Typography sx={{ fontFamily: "VisbyCF-Medium", color: "red" }}>{discountMessage}</Typography>
              </Box>
            )}

            <TextField
              sx={textStyle}
              id="poznamka"
              label="Poznámka"
              placeholder="Poznámka"
              multiline
              rows={4}
              variant="outlined"
              fullWidth
              margin="normal"
              required
              name="poznamka"
              value={formValues.poznamka}
              onChange={handleInputChange}
              inputProps={{
                maxLength: 100,
              }}
            />

            {certification === "t" && (
              <FormControlLabel
                control={<Checkbox checked={zaujemOCertifikaciu} onChange={handleZaujemOCertifikaciuChange} name="zaujemOCertifikaciu" />}
                label="Záujem o certifikáciu"
                sx={checkBox}
              />
            )}

            {zaujemOCertifikaciu && (
              <Box>
                <Typography
                  sx={{
                    fontFamily: "VisbyCF-Medium",
                    marginBottom: "-15px",
                    marginTop: "15px",
                    color: "rgba(0, 0, 0, 0.6)",
                    fontSize: "1rem",
                  }}
                >
                  Dátum narodenia *
                </Typography>
                <TextField
                  sx={textStyle}
                  type="date"
                  id="datum-narodenia"
                  // label="Dátum narodenia"
                  placeholder="Dátum narodenia"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  required
                  name="datumNarodenia"
                  value={formValues.datumNarodenia}
                  onChange={handleInputChange}
                />
              </Box>
            )}

            <FormControl component="fieldset" margin="normal">
              <FormLabel sx={{ fontFamily: "VisbyCF-Medium" }} component="legend">
                Spôsob platby
              </FormLabel>
              <RadioGroup aria-label="platba-typ" name="platbaTyp" value={platbaTyp} onChange={handlePlatbaTypChange}>
                <FormControlLabel
                  value="faktura"
                  control={<Radio />}
                  label={
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: "VisbyCF-Medium", // Font family for the label
                      }}
                    >
                      Platba cez faktúru
                    </Typography>
                  }
                  // required
                />
                {/* <FormControlLabel
                  value="platobna-brana"
                  control={<Radio />}
                  label={
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: "VisbyCF-Medium", // Font family for the label
                      }}
                    >
                      Platba cez platobnú bránu
                    </Typography>
                  }
                  // required
                /> */}
                {sukromnaOsoba && (
                  <FormControlLabel
                    value="kompas"
                    control={<Radio />}
                    label={
                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: "VisbyCF-Medium", // Font family for the label
                        }}
                      >
                        Záujem o financovanie cez Úrad práce - NEZAMESTNANÝ
                      </Typography>
                    }
                    // required
                  />
                )}

                {sukromnaOsoba && (
                  <FormControlLabel
                    value="nestrat-pracu"
                    control={<Radio />}
                    label={
                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: "VisbyCF-Medium", // Font family for the label
                        }}
                      >
                        Záujem o financovanie cez Úrad práce - ZAMESTNANÝ
                      </Typography>
                    }
                    // required
                  />
                )}

                {sukromnaOsoba && (
                  <FormControlLabel
                    value="pozicka"
                    control={<Radio />}
                    label={
                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: "VisbyCF-Medium", // Font family for the label
                        }}
                      >
                        Záujem o financovanie formou pôžičky
                      </Typography>
                    }
                    // required
                  />
                )}
              </RadioGroup>
            </FormControl>

            {(platbaTyp === "kompas" || platbaTyp === "nestrat-pracu") && (
              <TextField
                sx={textStyle}
                id="rodne-cislo"
                label="Rodné číslo"
                placeholder="Rodné číslo"
                variant="outlined"
                fullWidth
                margin="normal"
                required
                name="rodneCislo"
                value={formValues.rodneCislo}
                onChange={handleInputChange}
                inputProps={{
                  maxLength: 11, // Maximum character limit
                }}
              />
            )}
            {platbaTyp === "pozicka" && (
              <Box sx={{ marginTop: "20px", height: "30px" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={suhlasPoskytnutieUdajov}
                      onChange={handleSuhlasPoskytnutieUdajov}
                      name="suhlasPoskytnutieUdajovFinancnemuPartnerovi"
                      sx={{
                        marginTop: "-40px",
                      }}
                      required
                    />
                  }
                  label={
                    <Typography>
                      Súhlas s{" "}
                      <Link target="_blank" rel="noopener noreferrer" to="/podmienky">
                        poskytnutím údajov finančnému partnerovi
                      </Link>
                    </Typography>
                  }
                  sx={checkBox}
                />
              </Box>
            )}
            <Box sx={{ marginTop: "15px", height: "20px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                      checked={suhlasObchodnePodmienky}
                      onChange={handleSuhlasObchodnePodmienkyChange}
                      name="suhlasObchodnePodmienky"
                      sx={{
                        marginTop: "-22px",
                      }}
                      required
                  />
                }
                label={
                  <Typography>
                    Súhlas s{" "}
                    <Link target="_blank" rel="noopener noreferrer" to="https://aas-academy.sk/dokumenty/obchodne_podmienky">
                      obchodnými podmienkami
                    </Link>
                  </Typography>
                }
                sx={checkBox}
              />
            </Box>
            {isDateWithin14Days(formValues.datum) && (
                <Box sx={{ marginTop: "15px", height: "90px" }}>
                  <FormControlLabel
                      control={
                        <Checkbox
                            checked={suhlasZnizenieLehotyNaOdstupenie}
                            onChange={handleSuhlasZnizenieLehotyNaOdstupenie}
                            name="handleSuhlasZnizenieLehotyNaOdstupenie"
                            sx={{
                              marginTop: "-83px",
                            }}
                            required
                        />
                      }
                      label={
                        <Typography>
                          Spotrebiteľ súhlasí so začatím poskytovania služieb pred uplynutím 14-dňovej lehoty na odstúpenie od zmluvy.
                        </Typography>
                      }
                      sx={{
                        height: "80px",
                        "& .MuiTypography-root": {
                          fontFamily: "VisbyCF-Medium",
                          fontSize: 14,
                        },
                      }}
                  />
                </Box>
            )}
            <Box
              sx={{
                height: "auto",
                width: "100%",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <ReCAPTCHA ref={recaptchaRef} sitekey={SITE_KEY} onChange={handleExpiredToken} />
            </Box>
            <Box sx={{ width: "100%", display: "flex", justifyContent: "end" }}>
              <Button
                type="submit"
                variant="contained"
                disabled={
                  suhlasObchodnePodmienky &&
                  !SubmitDisabled &&
                  (isDiscountValid === true || formValues.zlavovyKod === "") &&
                  ((platbaTyp === "pozicka" && suhlasPoskytnutieUdajov) || (platbaTyp !== "pozicka")) &&
                  ((isDateWithin14Days(formValues.datum) && suhlasZnizenieLehotyNaOdstupenie) || (!isDateWithin14Days(formValues.datum)))
                    ? false
                    : true
                }
                sx={{ fontFamily: "VisbyCF-Medium", fontSize: 16 }}
              >
                {loading ? <CircularProgress size={20} /> : "Objednať"}
              </Button>
            </Box>
          </form>
        </Box>
      )}
      {submitted && (
        <Box
          sx={{
            maxWidth: "500px",
            width: "100%",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "50px",
            paddingBottom: "50px",
            paddingLeft: "15px",
            paddingRight: "15px",
            borderRadius: "22px",
            position: "relative",
            marginLeft: "15px",
            marginRight: "15px",
          }}
        >
          <img
            onClick={onClose}
            style={{
              cursor: "pointer",
              rotate: "45deg",
              position: "absolute",
              right: 5,
              top: 5,
            }}
            src={require("../../assets/red_plus.png")}
            alt="arrow_close"
          />
          <Typography
            sx={{
              color: "#1b144a",
              fontFamily: "VisbyCF-Bold",
              fontSize: "28px",
              lineHeight: "22px",
              marginBottom: "5px",
              textAlign: { md: "start", xs: "center" },
            }}
          >
            GRAATULUJEME!
          </Typography>
          <Typography
            sx={{
              color: "#1b144a",
              fontFamily: "VisbyCF-Bold",
              fontSize: { md: "22px", xs: "18px" },
              lineHeight: "22px",
              marginBottom: "5px",
              textAlign: { md: "start", xs: "center" },
            }}
          >
            Si o krok bližšie za lepšou kariérou.
          </Typography>
          <img src={require("../../assets/confetti.png")} alt="confetti" />
        </Box>
      )}
      {errorModal && (
        <Box
          sx={{
            maxWidth: "500px",
            width: "100%",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "50px",
            paddingBottom: "50px",
            paddingLeft: "15px",
            paddingRight: "15px",
            borderRadius: "22px",
            position: "relative",
            marginLeft: "15px",
            marginRight: "15px",
          }}
        >
          <img
            onClick={onClose}
            style={{
              cursor: "pointer",
              rotate: "45deg",
              position: "absolute",
              right: 5,
              top: 5,
            }}
            src={require("../../assets/red_plus.png")}
            alt="arrow_close"
          />
          <Typography
            sx={{
              color: "#1b144a",
              fontFamily: "VisbyCF-Bold",
              fontSize: "28px",
              lineHeight: "22px",
              marginBottom: "5px",
              textAlign: { md: "start", xs: "center" },
            }}
          >
            Nastala chyba
          </Typography>
          <Typography
            sx={{
              color: "#1b144a",
              fontFamily: "VisbyCF-Bold",
              fontSize: { md: "22px", xs: "18px" },
              lineHeight: "22px",
              marginBottom: "5px",
              textAlign: { md: "start", xs: "center" },
            }}
          >
            {errorMessage}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default Form;
