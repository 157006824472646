let instqbCourses;

export default instqbCourses = [
  {
    id: 1,
    name: "ISTQB Certifikovaný tester základná úroveň (CTFL)",
    certificate: "Tester - základná úroveň",
    length: "3-dňový kurz",
    tags: [
      "Testovanie počas celého životného cyklu softvéru",
      "Statické techniky",
      "Manažment testovania",
      "Dynamické testovacie techniky",
      "Základy testovania",
      "Podporné nástroje testovania",
    ],
    gain: `Školenie pokrýva <b>základy testovania softvéru</b>, okamžite aplikovateľné vo všetkých odvetviach. Poskytne testerom a testovacím manažérom základné myšlienky, potrebné procesy, nástroje a zručnosti pre <b>kariéru profesionála v testovaní softvéru</b>. Tento <b>intenzívny kurz zlepšuje porozumenie testovania</b> softvéru a zahŕňa <b>teóriu, príklady a praktické cvičenia</b>. <br/>Účastníci budú štruktúrovane prevedení základnými - štandardnými pojmami testovania, procesom testovania, <b>testovacími metódami čiernej skrinky, bielej skrinky, testovaním založeným na skúsenostiach, monitorovaním testov a metrík a príslušnými štandardmi</b>.`,
    gainMore:
      "Toto školenie ti poskytne praktické vedomosti nad rámec učebných osnov ISTQB, ktoré môžeš okamžite uplatniť. Využívame viac štýlov výučby na lepšie zapamätanie (ukážky, cvičenia zábavnou formou, prednáškové segmenty) a uchovanie v pamäti. Okrem základov testovania softvéru sa tiež dozvieš o vzťahu testovania k vývoju, výhodách a rizikách automatizácie testov, správy konfigurácií a nepretržitej integrácie, pričom zohľadňujeme moderné priemyselné trendy.<br/> Počas školenia obdržíš školiaci materiál v slovenskom jazyku. Jednoduché testy na overenie nadobudnutých vedomostí sú súčasťou jednotlivých kapitol.<br/>Po absolvovaní školenia budeš pripravený na <b>certifikačnú skúšku ISTQB Základný stupeň</b>. <br/>Zabezpečíme ti certifikačnú skúšku podľa záujmu po skončení školenia.",
    for: "Školenie je určené <b>každému, kto participuje priamo alebo nepriamo na testovaní softvéru</b>, resp. <b>na vývojovom a implementačnom životnom cykle vývoja softvéru</b>. Predovšetkým sa jedná o <b>manuálnych testerov, automatizovaných testerov, performance testerov, akceptačných testerov, test analytikov, test manažérov, IT konzultantov</b>. <br/>Rovnako užitočné je školenie aj <b>pre projektových manažérov</b>, prípadne ľudí z vývoja, nakoľko počas implementácie softvéru spolu úzko spolupracujú.",
    description:
      "Akreditované ISTQB školenie pokrýva základy testovania softvéru, aplikovateľné na všetky priemyselné odvetvia, v ktorých sa vyskytuje vývoj a implementácia softvéru.",
    showPrice: true,
    price: "719",
    lectors: [1, 2, 3],
    dates: [
      { start: "2023-06-12", end: "2023-06-14" },
      { start: "2023-07-24", end: "2023-07-26" },
      { start: "2023-09-18", end: "2023-09-20" },
      { start: "2023-10-16", end: "2023-10-18" },
      { start: "2023-11-06", end: "2023-11-08" },
      { start: "2023-12-04", end: "2023-12-06" },
    ],
  },
  {
    id: 13,
    length: "1-3 mesiace",
    tags: [],
    gain: "Praktická stáž u reálneho IT klienta na reálnych projektoch po dobu 1-3 mesiacov. Podmienkou pre praktickú stáž je absolvovanie aspoň základnej sady kurzov Akadémie AAS (Junior SW tester, Senior SW tester, SQL pokročilý) a jeden z kurzov zameraných na automatizáciu alebo performance testing.",
    gainMore: "",
    for: "",
    nameShort: "Praktická stáž",
    lectors: [],
    name: "Praktická stáž 1-3 mesiace",
    description:
        "Praktická stáž u IT klienta na reálnych projektoch. Dostupné pre absolventov sady kurzov Akadémie AAS.",
    showPrice: false,
    price: "",
    isPractice: true,
    dates: [],
    process: [],
    phrase:
        "Absolvovaním praktickej stáže získaš finálny certifikát o absolvovaní praxe + potvrdenie o absolvovaní praxe + “odporúčací list” a referencie.",
    btnTxt: "Chcem prax zadarmo",
  },
  {
    id: 14,
    length: "",
    tags: [],
    gain: "Školenie pokrýva prehľad základov testovania softvéru, preberané na školení ISTQB Foundation. Doplnené je o nové témy, poznatky a teóriu, ktorá bola doplnená do sylabov v roku 2023. Poskytne účastníkom prehľadný jasný popis nových tém, ktoré predchádzajúca verzia 3.1 ešte neobsahovala. Všetky novinky sú v rámci školenia zasadené do aktuálnych sylabov, prehľadne a rýchlo sa preberajú aj témy, ktoré sú vo verzii 4.0 bez zmeny a dôraz sa kladie na nové poznatky.",
    gainMore: "",
    for: "Školenie je určené pre absolventov školenia CTFL resp držiteľov CTFL certifikátu. Školenie je koncipované tak, že okrem nových tém preberie aj pôvodné témy CTFL aby sa nové poznatky zasadili do celkového kontextu CTFL problematiky a aby dalo ucelený pohľad aj účastníkom, ktorí CTFL školenie neabsolvovali.",
    description:
      "Najnovšie školenie v našom portfóliu ideálne pre držiteľov certifikátu CTFL - jasne a prehľadne popisuje rozdiely a hlavne novo pridané témy v nových sylaboch ISTQB Foundation pre verziu 4.0.",
    name: "",
    certificate: "",
    showPrice: true,
    price: "",
    lectors: [1, 2],
    dates: [],
  },
  {
    id: 2,
    length: "2-dňový kurz",
    tags: [
      "Agilný vývoj softvéru",
      "Základné princípy agilného testovania",
      "Postupy a procesy agilného testovania",
      "Agilné testovacie metódy",
      "Agilné techniky a nástroje",
    ],
    gain: "Nauč sa úlohu <b>testera v agilnom tíme</b> a preskúmaj <b>agilné testovacie procesy</b> a metódy v <b>interaktívnom workshope</b>. Toto školenie ťa pripraví na to, aby si sa stal hodnotným, uvedomelým <b>členom agilného vývojového tímu</b>, od návrhu <b>užívateľských príbehov</b> cez <b>vývoj a testovanie</b>.",
    for: "Toto školenie je určené <b>členom cross-funkčných tímov</b> v agilnom životnom cykle vývoja, ktorí chcú získať <b>skúsenosti s vyjednávaním a vykonávaním testovacích aktivít</b>, ako sú <b>softvéroví testeri</b>, či <b>testeri automatizéri</b>. </br>Školenie je tiež ideálne pre všetkých, ktorí majú záujem porozumieť konceptom <b>udržateľného testovania</b> v rámci životného cyklu agilného vývoja, a preto je vhodný aj pre <b>vývojárov</b>, Scrum masterov, <b>vlastníkov produktov</b> a <b>manažérov testov</b>.",
    gainMore:
      "Spoznáš hlavné agilné testovacie výzvy a ich riešenia, pochopíš agilný vývojový proces a jeho miesto v ňom, účel testovania v rámci životného cyklu vývoja agilného vývoja. Preskúmaš kľúčové postupy agilného testovania - ATDD, BDD, TDD a ET. Zlepšíš svoje zručnosti na včasné detegovanie chýb v agilnom vývojovom procese. Zlepšíš rokovacie a komunikačné zručnosti v rámci vývojového tímu a posilníš pozíciu testera na stretnutiach. </br>Školenie je vysoko interaktívne a využíva viac štýlov výučby na zvýšenie uchovania materiálu v pamäti.",
    name: "ISTQB Certifikovaný Agilný tester základná úroveň (CTFL-AT)",
    certificate: "Agilný tester - základná úroveň ",
    description:
      "Jedno z najžiadanejších ISTQB školení formou onsite/online workshopu na základnej úrovni zaoberajúce sa úlohou testera v agilnom tíme a agilnými testovacími procesmi.",
    showPrice: true,
    price: "599",
    lectors: [1, 3],
    dates: [
      { start: "2023-06-15", end: "2023-06-16" },
      { start: "2023-09-21", end: "2023-09-22" },
      { start: "2023-10-19", end: "2023-10-20" },
      { start: "2023-11-09", end: "2023-11-10" },
      { start: "2023-12-07", end: "2023-12-08" },
    ],
  },
  {
    id: 3,
    length: "5-dňový kurz",
    tags: [
      "Testovanie počas celého životného cyklu softvéru",
      "Statické techniky",
      "Manažment testovania",
      "Dynamické testovacie techniky",
      "Základy testovania",
      "Podporné nástroje testovania",
    ],
    gain: "Toto profesionálne školenie ti pomôže stavať na vedomostiach a zručnostiach základnej úrovne. Stretneš sa v ňom s množstvom praktických cvičení na <b>posilnenie pevného teoretického základu</b> a rozvinieš <b>pokročilé zručnosti na riadenie testovacieho tímu</b> a úspešné dodanie <b>projektu testovania softvéru</b>. Školenie zahŕňa <b>cvičenia</b> a skúšobné otázky, ktoré zdôrazňujú kľúčové aspekty sylabu, pomáhajú pochopiť a precvičiť prezentované koncepty a metódy a pripraviť účastníkov na certifikačnú skúšku.",
    gainMore:
      "Školenie využíva viac štýlov výučby na lepšie zapamätanie, ako ukážky, cvičenia zábavnou formou, či prednáškové segmenty. Cieľom školenia je poskytnúť pochopenie problémov riadenia testov nad rámec základnej úrovne a poskytnúť účastníkom vedomosti a zručnosti potrebné na to, aby sa stali pokročilými manažérmi testovania. </br>Školenie je pripravené ako 5 dňové školenie v rozsahu sylabov ISTQB Advanced Level Test Manager. <br/>Počas školenia získaš školiaci materiál v anglickom jazyku. <br/>Certifikačnú skúšku zabezpečíme podľa záujmu po skončení školenia. <br/>Podrobný obsahu tohto kurzu nájdeš v oficiálnom sylabe ISTQB, ktorý si môžeš pozrieť a stiahnuť z <a href='https://www.istqb.org/' target='_blank'>www.istqb.org.</a>",
    for: "<b>Intenzívny kurz</b> ideálny <b>pre profesionálov v oblasti testovania softvéru</b>, ktorí pôsobia v pozícii <b>test manažéra</b> alebo <b>vedúceho oddelenia testov</b>. <br/>Je vhodný pre každého, kto má záujem <b>napredovať v etablovanej kariére v oblasti testovania softvéru</b>. Ide o profesie ako <b>testeri, testovací analytici, inžinieri, konzultanti, test lídri, vedúci testovacích tímov</b>, či <b>manažéri testov</b>. Tento kurz je určený najmä pre testerov, ktorí sú <b>zodpovední za poskytovanie testov</b>. <br/>Školenie je rovnako užitočné pre záujemcov, ktorí chcú získať <b>pokročilé vedomosti o riadení testovania</b>, kariérne napredovať v testovaní a byť pripravení na <b>ISTQB certifikačnú skúšku</b>.",
    name: "ISTQB Test manager (CTAL - TM)",
    certificate: "Test manažér - pokročilá úroveň",
    description:
      "Toto ISTQB školenie, akreditované podľa najnovšej aktualizácie ISTQB® Advanced Level Test Management Syllabus v3.0 pomocou školiacich materiálov spoločnosti Tesena s.r.o. sa zameriava na zručnosti potrebné pri riadení testovania softvéru v rôznych kontextoch životného cyklu a projektov.",
    showPrice: true,
    price: "1329",
    lectors: [1, 2, 3, 4],
    dates: [{ start: "2023-11-20", end: "2023-11-24" }],
  },
  {
    id: 4,
    length: "3-dňový kurz",
    tags: [
      "Pokročilé aspekty a princípy testovania",
      "Rozšírené techniky testovania",
      "Príprava testovacích podkladov a vytváranie testovacích prípadov",
      "Incident management a reporting",
      "Nástroje pre testovanie a automatizáciu",
      "Etika a štandardy v testovaní",
    ],
    gain: "Umožňuje účastníkom dosiahnuť <b>vyššiu úroveň profesionality v testovaní softvéru</b>. Zvyšuje hodnotu a konkurencieschopnosť účastníka v IT odvetví. <b>Otvára nové kariérne možnosti</b>, vrátane pozícií s vyššou zodpovednosťou a lepšie platenej práce v oblasti testovania softvéru. Pokročilé aspekty a princípy testovania: Diskusia o úlohe a zodpovednostiach testovacích analytikov v celom procese testovania. Účastníci budú detailne zoznámení s <b>rozšírenými technikami testovania</b>, ktoré sa používajú pri <b>testovaní softvéru, vrátane čiernej a bielej skrinky, analýzy hodnôt hraníc, testovania ekvivalentných tried, testovania stavových prechodov a testovania použiteľnosti</b>. <br/>Zoznámia sa s prípravou testovacích podkladov a vytváraním testovacích prípadov. Naučia sa, ako pripraviť efektívne testovacie scenáre a prípady na základe požiadaviek a dizajnu systému. Prejdú aj témou <b>Incident management</b> a <b>reporting</b>, kde sa naučia ako efektívne zaznamenať, sledovať a spravovať chyby a problémy, ktoré sa vyskytnú počas testovania. </br>Zoznámia sa s nástrojmi a technológiami používanými v pokročilom testovaní pre samotné testovanie ako aj automatizáciu. Na záver školenia účastníka čaká diskusia o <b>etických štandardoch</b> a <b>najlepších praktikách</b>, ktoré by mali byť uplatňované pri testovaní softvéru.",
    gainMore: "",
    for: "Školenie je určené <b>pre profesionálov</b>, ktorí sa už zaoberajú testovaním softvéru a majú záujem o <b>rozšírenie a zdokonalenie svojich schopností a vedomostí na pokročilej úrovni</b>. <br/>Školenie je taktiež ideálne pre tých, ktorí už majú certifikáciu ISTQB na základnej úrovni (Foundation Level) a majú prax v oblasti testovania softvéru. To môže zahŕňať <b>testovacích analytikov, testovacích inžinierov, testovacích konzultantov, manažérov testovania a dokonca aj vývojárov softvéru</b>, ktorí sa chcú naučiť viac o pokročilých technikách testovania. Tiež je vhodné pre tých, ktorí sa chystajú na pozície súvisiace s analýzou testov, kde je dôraz kladený na navrhovanie a implementáciu testovacích scenárov v súlade so špecifickými požiadavkami a štandardmi. <br/>Školenie CTAL-AT by mohlo byť užitočné aj pre <b>projektových manažérov</b> a <b>iných členov softvérových tímov</b>, ktorí sa chcú lepšie zorientovať v pokročilých technikách testovania a výzvach súvisiacich s kvalitou softvéru.",
    name: "ISTQB Test Analyst (CTAL – TA)",
    certificate: "Test Analyst - pokročilá úroveň",
    description:
      "Školenie, akreditované podľa najnovšej aktualizácie ISTQB® Advanced Level Test Analyst Syllabus v3.1.1 pomocou školiacich materiálov spoločnosti Tesena s.r.o. sa sústreďuje na hlbšie pochopenie test procesov a začlenenie role test analytika do životného cyklu. Zároveň rozširuje znalosti testovacích techník z foundation level, prináša nové a pokročilejšie techniky v rámci black-box testovania.",
    showPrice: true,
    price: "1120",
    lectors: [6, 4],
    dates: [
      { start: "2023-07-17", end: "2023-07-19" },
      { start: "2023-09-25", end: "2023-09-27" },
      { start: "2023-11-13", end: "2023-11-15" },
    ],
  },
  {
    id: 5,
    length: "3-dňový kurz",
    tags: [
      "Základy automatizácie testovania",
      "Návrh a implementácia automatizácie testovania",
      "Riadenie automatizácie testovania",
      "Pokročilé koncepty automatizácie testovania",
      "Automatizácia testovania v kontexte rôznych paradigiem softvérového vývoja",
      "Praktické príklady a štúdie prípadov",
    ],
    gain: "Školenie CTAL-TAE poskytuje účastníkom <b>detailné porozumenie techník, nástrojov a procesov potrebných pre efektívnu automatizáciu testovania</b>. Toto hlboké pochopenie pomáha účastníkom <b>navrhnúť</b> a <b>implementovať efektívnejšie stratégie automatizácie testovania</b>. <br/>Získanie certifikácie CTAL-TAE je významným krokom v kariére v oblasti testovania softvéru a môže viesť k lepším pracovným príležitostiam a lepšiemu uplatneniu na trhu práce. V rámci školenia sa účastníci zoznámia so <b>základmi automatizácie testovania, pochopia úlohy</b> a dôležitosť <b>automatizácie testovania</b> v modernom softvérovom vývoji. <br/>Zoznámia sa s <b>návrhom</b> a <b>implementáciou automatizácie testovania</b>. Naučia sa, ako navrhovať a implementovať efektívne stratégie automatizácie testovania vrátane výberu správnych nástrojov a techník. Venovať sa budú tiež <b>riadeniu automatizácie testovania, naučia sa ako riadiť proces automatizácie testovania, vrátane sledovania</b> a <b>vyhodnocovania kvality testov</b>. Preberú tiež pokročilé koncepty automatizácie testovania a techniky, ako je testovanie kontinuálneho nasadenia, testovanie na základe modelov, a testovanie rozhraní API. Prediskutuje sa aj téma automatizácie testovania v kontexte rôznych paradigiem softvérového vývoja, ako je <b>Agile, DevOps</b> a <b>Continuous Delivery</b>. <br/>Na záver účastníkom ukážeme praktické príklady a štúdie prípadov, aplikáciu naučených konceptov na reálnych scenároch a problémoch.",
    gainMore: "",
    for: "Školenie Certified Tester Advanced Level - Test Automation Engineer (CTAL-TAE) je určené pre <b>profesionálnych testovacích inžinierov</b>, ktorí už majú skúsenosti s testovaním softvéru a chcú rozšíriť svoje znalosti v oblasti automatizácie testovania. Ďalej pre <b>inžinierov automatizácie</b>, ktorí chcú zlepšiť svoje schopnosti a techniky vytvárania a správy testovacích skriptov a infraštruktúry. Tiež pre <b>vývojárov softvéru</b> s cieľom lepšie pochopiť, ako sa automatizované testovanie integruje do celkového procesu vývoja softvéru a chcú zlepšiť kvalitu svojho kódu prostredníctvom efektívnejšieho testovania. Pre <b>vedúcich tímov</b>, majúcich záujem o zavedenie alebo zlepšenie praxe automatizácie testovania vo svojich tímoch. V neposlednom rade pre <b>konzultantov v oblasti kvality softvéru</b>, ktorí chcú poskytnúť lepšiu hodnotu pre svojich klientov prostredníctvom implementácie a poradenstva v oblasti automatizácie testovania. <br/>Základnou požiadavkou je, aby účastníci mali predchádzajúce skúsenosti s testovaním softvéru a základnú znalosť programovania a skriptovania, keďže pokročilá úroveň kurzu predpokladá znalosť týchto oblastí. <b>Nevyhnutné je aj absolvovanie školenia na úrovni CTFL (Certified Tester Foundation Level)</b> pred nástupom na toto školenie.",
    description:
      "Školenie, akreditované podľa najnovšej aktualizácie ISTQB® CT Test Automation Engineer v1.0 pomocou školiacich materiálov spoločnosti Tesena s.r.o. pokrýva dizajn a generickú architektúru riešenia pre test automatizáciu. Prechádza od základných princípov ako vyberať a tvoriť toto riešenie až po spôsob ako pripraviť firmu na jeho zavedenie.",
    name: "ISTQB Test Automation Engineer (CTAL – TAE)",
    certificate: "Test Automation Engineer - pokročilá úroveň",
    showPrice: true,
    price: "1100",
    lectors: [6, 4],
    dates: [
      { start: "2023-08-07", end: "2023-08-09" },
      { start: "2023-10-23", end: "2023-10-25" },
      { start: "2023-12-09", end: "2023-12-11" },
    ],
  },
];
