import { Box, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import React, { useState, useEffect } from "react";

const Istqb = () => {
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger the animation once when scrolled into view
  });
  const [secondRef, inViewSecond] = useInView({
    triggerOnce: true, // Only trigger the animation once when scrolled into view
  });

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    // Update the window width when the component mounts
    setWindowWidth(window.innerWidth);

    // Add event listener to update the window width on resize
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: "#7b7cd5",
          display: "flex",
          justifyContent: "center",
          flexDirection: { md: "column", xs: "row" },
          alignItems: "center",
          paddingLeft: "6.6vw",
          paddingRight: "6.6vw",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            maxWidth: "1440px",
            width: "100%",
            marginTop: "92px",
            marginBottom: "45px",
          }}
        >
          <motion.div
            ref={secondRef}
            initial={{ opacity: 0, y: 50 }}
            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
            transition={{ duration: 0.7 }}
          >
            <Typography
              sx={{
                fontSize: { md: 38, xs: 30 },
                fontFamily: "VisbyCF-Medium",
                color: "#1b144a",
                lineHeight: "44px",
                textAlign: "center",
                marginBottom: "32px",
              }}
            >
              <span style={{ color: "white" }}>Sme prví </span>školitelia na
              Slovensku s akreditovanými {windowWidth > 1024 ? <br /> : ""}
              školiacimi materiálmi v slovenskom jazyku
            </Typography>
          </motion.div>
          <motion.div
            ref={ref}
            initial={{ opacity: 0, y: 50 }}
            animate={
              inViewSecond ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }
            }
            transition={{ duration: 0.7 }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: { md: "row", xs: "column" },
                alignItems: { md: "start", xs: "center" },
              }}
            >
              <Box
                sx={{
                  marginRight: { md: "15px", xs: "0px" },
                }}
              >
                <img
                  src={require("../../assets/istqb_screen.png")}
                  alt="istqb_screen"
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    marginTop: "52px",
                    fontSize: { md: 22, xs: 20 },
                    fontFamily: "VisbyCF-Medium",
                    color: "#1d1a56",
                    lineHeight: "28px",
                    maxWidth: "772px",
                    width: "100%",
                    textAlign: { md: "start", xs: "center" },
                  }}
                >
                  ISTQB je medzinárodnou autoritou pre testovanie softvéru.
                  Poskytujeme akreditované ISTQB školenia a odborné
                  špecializované školenia rôznych oblastí testovania. Všetci
                  naši školitelia sú profesionáli s viac ako 10 ročnými
                  praktickými skúsenosťami v oblasti IT.
                </Typography>
                <Box
                  sx={{
                    marginTop: "39px",
                    marginLeft: { xs: "0px" },
                    display: "flex",
                    flexDirection: windowWidth > 1175 ? "row" : "column",
                    width: "100%",
                    alignItems: { md: "end", xs: "end" },
                    justifyContent: "end",
                  }}
                >
                  <Link
                    to="/skolenia#specializovane"
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      sx={{
                        borderRadius: "28px",
                        border: "2px solid",
                        borderColor: "#3fcaea",
                        backgroundColor: "transparent",
                        fontFamily: "VisbyCF-Medium",
                        fontSize: 16,
                        cursor: "pointer",
                        color: "white",
                        height: "55px",
                        width: "350px",
                        marginRight: windowWidth > 1175 ? "16px" : "0",
                        textTransform: "initial",
                        marginBottom: "10px",
                        padding: 0,
                        "&:hover": {
                          borderColor: "white",
                        },
                      }}
                    >
                      Mám záujem o špecializované školenie
                    </Button>
                  </Link>
                  <Link to="/skolenia#istqb" style={{ textDecoration: "none" }}>
                    <Button
                      sx={{
                        borderRadius: "28px",
                        backgroundColor: "#f6961d",
                        fontFamily: "VisbyCF-Bold",
                        fontSize: 16,
                        cursor: "pointer",
                        color: "white",
                        height: "55px",
                        width: "268px",
                        marginBottom: "10px",
                        textTransform: "initial",
                        "&:hover": {
                          backgroundColor: "#3fcaea",
                        },
                      }}
                    >
                      Viac o školeniach ISTQB
                    </Button>
                  </Link>
                </Box>
              </Box>
            </Box>
          </motion.div>
        </Box>
      </Box>
    </Box>
  );
};

export default Istqb;
