import { Box, Typography, Grid, Button } from "@mui/material";
import styles from "../global/header.module.css";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import icon from "../../assets/skolenie_ikona_orangeyellow.svg";

const Courses = ({ courses, setContactForm, setContactMessage, fetchedData, windowWidth }) => {
  const shadowStyle = "0px 2px 4px rgba(0, 0, 0, 0.1)"; // Customize the shadow value as needed
  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger the animation once when scrolled into view
  });
  const [products, inViewProducts] = useInView({
    triggerOnce: true, // Only trigger the animation once when scrolled into view
  });

  const openForm = () => {
    setContactForm(true);
    setContactMessage("");
  };

  return (
    <Box
      id="specializovane"
      sx={{
        backgroundColor: "#d8d8d8",
        display: "flex",
        justifyContent: "center",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          maxWidth: "1440px",
          width: "100%",
          position: "relative",
        }}
      >
        <Box
          sx={{
            marginTop: { sm: "120px", xs: "70px" },
            marginBottom: "50px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2,
            position: "relative",
          }}
        >
          <motion.div
            ref={ref}
            initial={{ opacity: 0, y: 50 }}
            animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
            transition={{ duration: 0.7 }}
          >
            <Typography
              sx={{
                textAlign: "center",
                fontFamily: "VisbyCF-Medium",
                fontSize: 38,
                color: "#1d1a56",
                marginBottom: "15px",
                lineHeight: "40px",
              }}
            >
                Testovanie softvéru
            </Typography>
            <Typography
              sx={{
                textAlign: "center",
                fontFamily: "VisbyCF-Medium",
                fontSize: 22,
                lineHeight: "28px",
                color: "#1d1a56",
                maxWidth: "480px",
                paddingLeft: "15px",
                paddingRight: "15px",
              }}
            >
              Ponúkame školenia formou <span style={{ fontFamily: "VisbyCF-Bold" }}>online</span> výuky. Možnosť aj{" "}
              <span style={{ fontFamily: "VisbyCF-Bold" }}>onsite školení</span> na základe dohody.
            </Typography>
          </motion.div>
          <Link
            onClick={() => openForm()}
            style={{
              textAlign: "center",
              fontFamily: "VisbyCF-Bold",
              fontSize: 22,
              lineHeight: "28px",
              color: "#1d1a56",
              decoration: "underline",
            }}
          >
            kontaktovať
          </Link>
        </Box>
        <motion.div
          ref={products}
          initial={{ opacity: 0, y: 50 }}
          animate={inViewProducts ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
          transition={{ duration: 0.7 }}
        >
          <Grid
            container
            sx={{
              paddingLeft: windowWidth < 2700 ? "9vw" : "0",
              paddingRight: windowWidth < 2700 ? "9vw" : "0",
              position: "relative",
              zIndex: 2,
              display: "flex",
              justifyContent: { lg: "center", xs: "center" },
              alignItems: "center",
              gap: "50px",
              marginBottom: "120px",
            }}
          >
            {courses.map((course, index) => {
              return (
                <Grid
                  key={index}
                  item
                  // xs={12}
                  // sm={6}
                  // md={4}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop: "23px",
                    width: "283px", // Styles for the element
                    // Set initial scale
                    transform: "scale(1)",
                    transition: "transform 0.3s ease",
                    "&:hover": {
                      // Styles on hover
                      transform: "scale(1.1)",
                    },
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {course.isPractice ? (
                      <Box sx={{ height: "37px" }}></Box>
                    ) : (
                      <Box
                        sx={{
                          height: "37px",
                          width: "178px",
                          backgroundColor: "#7b7cd5",
                          borderTopLeftRadius: "22px",
                          borderTopRightRadius: "22px",
                          justifyContent: "center",
                          display: "flex",
                          alignItems: "end",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "VisbyCF-ExtraBold",
                            fontSize: 18,
                            color: "white",
                            marginBottom: "1px",
                          }}
                        >
                          online/onsite
                        </Typography>
                      </Box>
                    )}

                    <Box
                      sx={{
                        height: "600px",
                        // TODO
                        // width: "281px",
                        backgroundColor: "white",
                        borderRadius: "20px",
                        boxShadow: shadowStyle,
                        display: "flex",
                        alignItems: "center",
                        // justifyContent: "center",
                        justifyContent: "space-between",
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "column",
                          height: "225px",
                        }}
                      >
                        <Box
                          sx={{
                            width: "77px",
                            height: "77px",
                            marginTop: "30px",
                            display: "flex",
                            position: "relative",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <img
                            src={icon}
                            alt="student_hat"
                            style={{
                              position: "absolute",
                              height: "150px",
                              marginBottom: "15px",
                            }}
                          />
                        </Box>
                        <Typography
                          sx={{
                            fontFamily: "VisbyCF-DemiBold",
                            fontSize: 22,
                            marginTop: "19px",
                            color: "#7b7cd5",
                            textAlign: "center",
                            marginRight: "25px",
                            marginLeft: "25px",
                            lineHeight: "26px",
                          }}
                        >
                          {fetchedData.find((obj) => obj.id === course.id) ? fetchedData.find((obj) => obj.id === course.id).name : ""}
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "VisbyCF-DemiBold",
                            fontSize: 22,
                            color: "#7b7cd5",
                            textAlign: "center",
                            marginRight: "25px",
                            marginLeft: "25px",
                            lineHeight: "26px",
                          }}
                        >
                          {fetchedData.find((obj) => obj.id === course.id) ? fetchedData.find((obj) => obj.id === course.id).duration_text : ""}
                        </Typography>
                      </Box>
                      <Typography
                        sx={{
                          fontFamily: "VisbyCF-Medium",
                          fontSize: 18,
                          lineHeight: "22px",
                          color: "#1b144a",
                          textAlign: "center",
                          marginRight: "25px",
                          marginLeft: "25px",
                          marginBottom: "30px",
                        }}
                      >
                        {course.description}
                      </Typography>

                      {course.showPrice ? (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            height: "120px",
                          }}
                        >
                          {fetchedData.find((obj) => obj.id === course.id) && (
                            <Box sx={{ display: "flex" }}>
                              <Typography
                                sx={{
                                  fontFamily: "VisbyCF-ExtraBold",
                                  fontSize: 32,
                                  lineHeight: "36px",
                                  color: "#1d1a56",
                                  textAlign: "center",
                                }}
                              >
                                {fetchedData.find((obj) => obj.id === course.id).price} €
                              </Typography>
                              <Typography
                                sx={{
                                  fontFamily: "VisbyCF-Medium",
                                  fontSize: 16,
                                  marginTop: "2px",
                                  marginLeft: "6px",
                                  lineHeight: "36px",
                                  color: "#1d1a56",
                                  textAlign: "center",
                                }}
                              >
                                bez DPH
                              </Typography>
                            </Box>
                          )}
                          <Button
                            component="a"
                            href={`/skolenie/${course.id}`}
                            sx={{
                              borderRadius: "28px",
                              border: "2px solid",
                              marginTop: "21px",
                              borderColor: "#7b7cd5",
                              fontFamily: "VisbyCF-Medium",
                              fontSize: 16,
                              cursor: "pointer",
                              color: "#1d1a56",
                              height: "44px",
                              width: "186px",
                              textTransform: "initial",
                              "&:hover": {
                                backgroundColor: "#7b7cd5",
                                color: "white",
                              },
                            }}
                          >
                            Viac o školení
                          </Button>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                            height: "120px",
                          }}
                        >
                          <Box sx={{ display: "flex" }}>
                            <Typography
                              sx={{
                                fontFamily: "VisbyCF-ExtraBold",
                                fontSize: 22,
                                lineHeight: "36px",
                                color: "#1d1a56",
                                textAlign: "center",
                              }}
                            >
                              {course.isPractice ? "ZADARMO" : "Na požiadanie"}
                            </Typography>
                          </Box>
                          <Button
                            component="a"
                            href={`/skolenie/${course.id}`}
                            sx={{
                              borderRadius: "28px",
                              border: "2px solid",
                              marginTop: "23px",
                              borderColor: "#7b7cd5",
                              fontFamily: "VisbyCF-Medium",
                              fontSize: 16,
                              cursor: "pointer",
                              color: "#1d1a56",
                              height: "44px",
                              width: "186px",
                              textTransform: "initial",
                              "&:hover": {
                                backgroundColor: "#7b7cd5",
                                color: "white",
                              },
                            }}
                          >
                            Viac o školení
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </motion.div>
        <img
          className={styles.element}
          style={{
            position: "absolute",
            top: 100,
            zIndex: 1,
          }}
          src={require("../../assets/elements2.png")}
          alt="academy_logo"
        />
        <div
          style={{
            position: "absolute",
            bottom: 100,
            zIndex: 1,
            transform: "scaleX(-1)",
          }}
        >
          <img className={styles.element} src={require("../../assets/elements2.png")} alt="academy_logo_bottom" />
        </div>
      </Box>
    </Box>
  );
};

export default Courses;
