import { Box, Typography } from "@mui/material";
import Footer from "../../components/global/Footer";
import { Link } from "@mui/material";

const Podmienky = () => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "#d8d8d8",
          alignItems: "center",
          flexDirection: "column",
          position: "relative",
        }}
      >
        <Typography>
          <Box
            sx={{
              maxWidth: "1440px",
              width: "100%",
              marginTop: "45px",
              marginBottom: "45px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              zIndex: 3,
            }}
          >
            <Typography
              sx={{
                fontSize: { md: 38, xs: 22 },
                fontFamily: "VisbyCF-Bold",
                color: "#1b144a",
                lineHeight: "44px",
                textAlign: "center",
              }}
            >
              Podmienky školenia na splátky
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: { md: "row", xs: "column" },
                alignItems: { md: "start", xs: "center" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  alignItems: "center",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                }}
              >
                <Typography
                  sx={{
                    marginTop: "52px",
                    fontSize: { md: 22, xs: 16 },
                    fontFamily: "VisbyCF-Medium",
                    color: "#1d1a56",
                    lineHeight: "28px",
                    maxWidth: "772px",
                    width: "100%",
                    textAlign: "start",
                  }}
                >
                  Podmienky pre program Školenie na splátky platí pre využitie služieb vzdelávania formou splátok tretej strane (ďalej len
                  <span style={{ fontFamily: "VisbyCF-Bold" }}>„podmienky školenia na splátky“</span>
                  ), ktorých poskytovateľom je:
                  <br />
                  <br />
                  <span style={{ fontFamily: "VisbyCF-Bold" }}>Spoločnosť:</span> AAS Solutions a.s.
                  <br />
                  <span style={{ fontFamily: "VisbyCF-Bold" }}>Adresa:</span> Plynárenská 7/A, 821 09 Bratislava
                  <br />
                  <span style={{ fontFamily: "VisbyCF-Bold" }}>Štát:</span> SLOVENSKO
                  <br />
                  <span style={{ fontFamily: "VisbyCF-Bold" }}>IČO:</span> 55 491 821
                  <br />
                  <span style={{ fontFamily: "VisbyCF-Bold" }}>DIČ:</span> 2122013421
                  <br />
                  <span style={{ fontFamily: "VisbyCF-Bold" }}>IČ DPH:</span> SK2122013421
                  <br />
                  <span style={{ fontFamily: "VisbyCF-Bold" }}>Zapísaná:</span> OR Mestského súdu Bratislava III, Oddiel Sa, Vložka č: 7573/B
                  <br />
                  <span style={{ fontFamily: "VisbyCF-Bold" }}>Banka:</span> UniCredit Bank Czech Republic and Slovakia, a.s. pobočka zahraničnej
                  banky
                  <br />
                  <span style={{ fontFamily: "VisbyCF-Bold" }}>SWIFT:</span> UNCRSKBX
                  <br />
                  <span style={{ fontFamily: "VisbyCF-Bold" }}>IBAN:</span> SK29 1111 0000 0017 5820 5004
                  <br />
                  <span style={{ fontFamily: "VisbyCF-Bold" }}>Telefón:</span>
                  <br />
                  +421 915 110 948
                  <br />
                  <span style={{ fontFamily: "VisbyCF-Bold" }}>E-mail:</span> academy@aassolutions.sk
                  <br />
                  (ďalej len „poskytovateľ“)
                  <br />
                  <br />
                  Produkt Školenie na splátky je určený pre fyzické osoby – podnikateľov, a rovnako aj pre spotrebiteľov (fyzická osoba –
                  nepodnikateľ).
                  <br />
                  <br /> V prípade záujmu financovania školenia na splátky účastník udeľuje súhlas na poskytnutie svojich osobných údajov spoločnosti{" "}
                  <span style={{ fontFamily: "VisbyCF-Bold" }}>PARTNERS GROUP SK, s. r. o</span>
                  , so sídlom: Aupark Tower, Einsteinova 3541/24, 851 01 Bratislava zapísanej v Obchodnom registri Okresného súdu Bratislava I, oddiel
                  Sro, vložka č. 44999/B za účelom poskytnutia úveru na financovanie školenia vrátane prípadnej certifikačnej skúšky.
                  <br />
                  <br />
                  <span style={{ fontFamily: "VisbyCF-Bold" }}>Rozsah spracúvaných osobných údajov:</span>
                  <br />
                  <br />{" "}
                  <span style={{ fontFamily: "VisbyCF-MediumOblique" }}>
                    - Meno, priezvisko, email, telefónne číslo, zvolený termín školenia, predpokladaná cena kurzu a certifikačnej skúšky
                  </span>
                  <br />
                  <br /> Tento súhlas so spracúvaním osobných údajov sa udeľuje na obdobie 10 rokov odo dňa jeho podpisu, resp. až do odvolania tohoto
                  súhlasu. Vyššie uvedený súhlas je možné kedykoľvek odvolať, a to nasledujúcim spôsobom:{" "}
                  <span style={{ fontFamily: "VisbyCF-MediumOblique" }}>zaslaním e-mailu prevádzkovateľovi</span>
                  <br />
                  <br />
                  <span style={{ fontFamily: "VisbyCF-Bold" }}>POUČENIE O PRÁVACH DOTKNUTEJ OSOBY</span>
                  <br />
                  <br />
                  NARIADENIE EURÓPSKEHO PARLAMENTU A RADY (EÚ) 2016/679 z 27. apríla 2016 o ochrane fyzických osôb pri spracúvaní osobných údajov a o
                  voľnom pohybe takýchto údajov, ktorým sa zrušuje smernica 95/46/ES (všeobecné nariadenie o ochrane údajov) (ďalej len{" "}
                  <span style={{ fontFamily: "VisbyCF-Bold" }}>„Nariadenie“)</span>
                  <br />
                  <br />
                  <span style={{ fontFamily: "VisbyCF-Bold" }}>Právo dotknutej osoby na prístup k údajom</span>
                  <br />
                  <br />
                  Dotknutá osoba má právo získať od prevádzkovateľa potvrdenie o tom, či sa spracúvajú osobné údaje, ktoré sa jej týkajú, a ak tomu
                  tak je, má právo získať prístup k týmto osobným údajom a tieto informácie:
                  <br />
                  <br />
                  <span style={{ marginRight: "30px" }}>a)</span> účely spracúvania;
                  <br />
                  <br />
                  <span style={{ marginRight: "30px" }}>b)</span> kategórie dotknutých osobných údajov;
                  <br />
                  <br />
                  <span style={{ marginRight: "30px" }}>c)</span> príjemcovia alebo kategórie príjemcov, ktorým boli alebo budú osobné údaje
                  poskytnuté, najmä príjemcovia v tretích krajinách alebo medzinárodné organizácie;
                  <br />
                  <br />
                  <span style={{ marginRight: "30px" }}>d)</span> ak je to možné, predpokladaná doba uchovávania osobných údajov alebo, ak to nie je
                  možné, kritériá na jej určenie;
                  <br />
                  <br />
                  <span style={{ marginRight: "30px" }}>e)</span> existencia práva požadovať od prevádzkovateľa opravu osobných údajov týkajúcich sa
                  dotknutej osoby alebo ich vymazanie alebo obmedzenie spracúvania, alebo práva namietať proti takémuto spracúvaniu;
                  <br />
                  <br />
                  <span style={{ marginRight: "30px" }}>f)</span> právo podať sťažnosť dozornému orgánu;
                  <br />
                  <br />
                  <span style={{ marginRight: "30px" }}>g)</span> ak sa osobné údaje nezískali od dotknutej osoby, akékoľvek dostupné informácie,
                  pokiaľ ide o ich zdroj;
                  <br />
                  <br />
                  <span style={{ marginRight: "30px" }}>h)</span> existencia automatizovaného rozhodovania vrátane profilovania a v týchto prípadoch
                  aspoň zmysluplné informácie o použitom postupe, ako aj význame a predpokladaných dôsledkoch takéhoto spracúvania pre dotknutú osobu.{" "}
                  <br />
                  <br />
                  Prevádzkovateľ poskytne kópiu osobných údajov, ktoré sa spracúvajú. Za akékoľvek ďalšie kópie, o ktoré dotknutá osoba požiada, môže
                  prevádzkovateľ účtovať primeraný poplatok zodpovedajúci administratívnym nákladom. Ak dotknutá osoba podala žiadosť elektronickými
                  prostriedkami, informácie sa poskytnú v bežne používanej elektronickej podobe, pokiaľ dotknutá osoba nepožiadala o iný spôsob.{" "}
                  <br />
                  <br />
                  <span style={{ fontFamily: "VisbyCF-Bold" }}>Právo na opravu</span>
                  <br />
                  <br /> Dotknutá osoba má právo na to, aby prevádzkovateľ bez zbytočného odkladu opravil nesprávne osobné údaje, ktoré sa jej týkajú.
                  So zreteľom na účely spracúvania má dotknutá osoba právo na doplnenie neúplných osobných údajov, a to aj prostredníctvom poskytnutia
                  doplnkového vyhlásenia.
                  <br />
                  <br /> <span style={{ fontFamily: "VisbyCF-Bold" }}>Právo na vymazanie (právo „na zabudnutie“)</span>
                  <br />
                  <br /> Dotknutá osoba má tiež právo dosiahnuť u prevádzkovateľa bez zbytočného odkladu vymazanie osobných údajov, ktoré sa jej
                  týkajú, a prevádzkovateľ je povinný bez zbytočného odkladu vymazať osobné údaje, ak je splnený niektorý z týchto dôvodov:
                  <br />
                  <br />
                  <span style={{ marginRight: "30px" }}>a)</span> osobné údaje už nie sú potrebné na účely, na ktoré sa získavali alebo inak
                  spracúvali;
                  <br />
                  <br />
                  <span style={{ marginRight: "30px" }}>b)</span> dotknutá osoba odvolá súhlas, na základe ktorého sa spracúvanie vykonáva a ak
                  neexistuje iný právny základ pre spracúvanie; <br />
                  <br />
                  <span style={{ marginRight: "30px" }}>c)</span> dotknutá osoba namieta voči spracúvaniu podľa článku 21 ods. 1 Nariadenia
                  a neprevažujú žiadne oprávnené dôvody na spracúvanie alebo dotknutá osoba namieta voči spracúvaniu podľa článku 21 ods. 2
                  Nariadenia;
                  <br />
                  <br />
                  <span style={{ marginRight: "30px" }}>d)</span> osobné údaje sa spracúvali nezákonne; <br />
                  <br />
                  <span style={{ marginRight: "30px" }}>e)</span> osobné údaje musia byť vymazané, aby sa splnila zákonná povinnosť podľa práva Únie
                  alebo práva členského štátu, ktorému prevádzkovateľ podlieha;
                  <br />
                  <br />
                  <span style={{ marginRight: "30px" }}>f)</span> osobné údaje sa získavali v súvislosti s ponukou služieb informačnej spoločnosti
                  podľa článku 8 ods. 1. Nariadenia
                  <br />
                  <br />
                  Dotknutá osoba nemá právo na vymazanie, pokiaľ je spracúvanie potrebné:
                  <br />
                  <br />
                  <span style={{ marginRight: "30px" }}>a)</span> na uplatnenie práva na slobodu prejavu a na informácie;
                  <br />
                  <br />
                  <span style={{ marginRight: "30px" }}>b)</span> na splnenie zákonnej povinnosti, ktorá si vyžaduje spracúvanie podľa práva Únie
                  alebo práva členského štátu, ktorému prevádzkovateľ podlieha, alebo na splnenie úlohy realizovanej vo verejnom záujme alebo pri
                  výkone verejnej moci zverenej prevádzkovateľovi;
                  <br />
                  <br />
                  <span style={{ marginRight: "30px" }}>c)</span> z dôvodov verejného záujmu v oblasti verejného zdravia
                  <br />
                  <br />
                  <span style={{ marginRight: "30px" }}>d)</span> na účely archivácie vo verejnom záujme, na účely vedeckého alebo historického
                  výskumu či na štatistické účely, pokiaľ je pravdepodobné, že právo na vymazanie znemožní alebo závažným spôsobom sťaží dosiahnutie
                  cieľov takéhoto spracúvania, alebo
                  <br />
                  <br />
                  <span style={{ marginRight: "30px" }}>e)</span> na preukazovanie, uplatňovanie alebo obhajovanie právnych nárokov.
                  <br />
                  <br />
                  <span style={{ fontFamily: "VisbyCF-Bold" }}>Právo na obmedzenie spracúvania</span>
                  <br />
                  <br />
                  Dotknutá osoba má právo na to, aby prevádzkovateľ obmedzil spracúvanie, pokiaľ ide o jeden z týchto prípadov:
                  <br />
                  <br />
                  <span style={{ marginRight: "30px" }}>a)</span> dotknutá osoba napadne správnosť osobných údajov, a to počas obdobia umožňujúceho
                  prevádzkovateľovi overiť správnosť osobných údajov;
                  <br />
                  <br />
                  <span style={{ marginRight: "30px" }}>b)</span> spracúvanie je protizákonné a dotknutá osoba namieta proti vymazaniu osobných údajov
                  a žiada namiesto toho obmedzenie ich použitia;
                  <br />
                  <br />
                  <span style={{ marginRight: "30px" }}>c)</span> prevádzkovateľ už nepotrebuje osobné údaje na účely spracúvania, ale potrebuje ich
                  dotknutá osoba na preukázanie, uplatňovanie alebo obhajovanie právnych nárokov;
                  <br />
                  <br />
                  <span style={{ marginRight: "30px" }}>d)</span> dotknutá osoba namietala voči spracúvaniu podľa článku 21 ods. 1 Nariadenia, a to až
                  do overenia, či oprávnené dôvody na strane prevádzkovateľa prevažujú nad oprávnenými dôvodmi dotknutej osoby.
                  <br />
                  <br />
                  Ak sa spracúvanie obmedzilo, takéto osobné údaje sa s výnimkou uchovávania spracúvajú len so súhlasom dotknutej osoby alebo na
                  preukazovanie, uplatňovanie alebo obhajovanie právnych nárokov, alebo na ochranu práv inej fyzickej alebo právnickej osoby, alebo z
                  dôvodov dôležitého verejného záujmu Únie alebo členského štátu.
                  <br />
                  <br />
                  Dotknutú osobu, ktorá dosiahla obmedzenie spracúvania, prevádzkovateľ informuje pred tým, ako bude obmedzenie spracúvania zrušené.
                  <br />
                  <br />
                  <span style={{ fontFamily: "VisbyCF-Bold" }}>
                    Oznamovacia povinnosť v súvislosti s opravou alebo vymazaním osobných údajov alebo obmedzením spracúvania
                  </span>
                  <br />
                  <br />
                  Prevádzkovateľ oznámi každému príjemcovi, ktorému boli osobné údaje poskytnuté, každú opravu alebo vymazanie osobných údajov alebo
                  obmedzenie spracúvania, pokiaľ sa to neukáže ako nemožné alebo si to nevyžaduje neprimerané úsilie. Prevádzkovateľ o týchto
                  príjemcoch informuje dotknutú osobu, ak to dotknutá osoba požaduje.
                  <br />
                  <br />
                  <span style={{ fontFamily: "VisbyCF-Bold" }}>Právo na prenosnosť údajov</span>
                  <br />
                  <br />
                  Dotknutá osoba má právo získať osobné údaje, ktoré sa jej týkajú a ktoré poskytla prevádzkovateľovi, v štruktúrovanom, bežne
                  používanom a strojovo čitateľnom formáte a má právo preniesť tieto údaje ďalšiemu prevádzkovateľovi bez toho, aby jej
                  prevádzkovateľ, ktorému sa tieto osobné údaje poskytli, bránil. <br />
                  <br />
                  Dotknutá osoba má pri uplatňovaní svojho práva na prenosnosť údajov právo na prenos osobných údajov priamo od jedného
                  prevádzkovateľa druhému prevádzkovateľovi, pokiaľ je to technicky možné.
                  <br />
                  <br />
                  <span style={{ fontFamily: "VisbyCF-Bold" }}>Právo namietať</span>
                  <br />
                  <br />
                  Ak sa osobné údaje spracúvajú na účely priameho marketingu, dotknutá osoba má právo kedykoľvek namietať proti spracúvaniu osobných
                  údajov, ktoré sa jej týka, na účely takéhoto marketingu, vrátane profilovania v rozsahu, v akom súvisí s takýmto priamym
                  marketingom.
                  <br />
                  <br />
                  Ak dotknutá osoba namieta voči spracúvaniu na účely priameho marketingu, osobné údaje sa už na také účely nesmú spracúvať.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Typography>
      </Box>
      {/* <Footer /> */}
    </Box>
  );
};

export default Podmienky;
