import React, { useState } from "react";
import { Box, Button, Grid, Collapse, Typography } from "@mui/material";
import styles from "../../pages/academy/academy.module.css";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import redPlus from "../../assets/red_plus.svg";
import redMinus from "../../assets/red_minus.svg";
import orangePlus from "../../assets/orange_plus.svg";
import orangeMinus from "../../assets/orange_minus.svg";
import bluePlus from "../../assets/blue_plus.svg";
import blueMinus from "../../assets/blue_minus.svg";
import arrow from "../../assets/arrow_blue.svg";
import {scrollToSection} from "../../helpers/interactivityHelper";

const Kompas = ({ setContactForm, setContactMessage }) => {
  const [isOpen, setIsOpen] = useState(true);

  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger the animation once when scrolled into view
  });
  const [products, inViewProducts] = useInView({
    triggerOnce: true, // Only trigger the animation once when scrolled into view
  });

  const toggleCollapse = () => {
    if (!isOpen) {
      setIsOpenAbout(false);
      setIsOpenNegative(false);
    }
    setIsOpen(!isOpen);
  };
  const [isOpenAbout, setIsOpenAbout] = useState(false);

  const toggleCollapseAbout = () => {
    if (!isOpenAbout) {
      setIsOpen(false);
      setIsOpenNegative(false);
    }
    setIsOpenAbout(!isOpenAbout);
  };
  const [isOpenNegative, setIsOpenNegative] = useState(false);

  const toggleCollapseNegative = () => {
    if (!isOpenNegative) {
      setIsOpenAbout(false);
      setIsOpen(false);
      if (isOpenAbout) {
          scrollToSection("kompas-about");
      }
    }
    setIsOpenNegative(!isOpenNegative);
  };

  const openForm = () => {
    setContactForm(true);
    setContactMessage("Potrebujem poradiť na tému Zručnosti pre trh práce pre nezamestnaných");
  };

  return (
    <Box
      id="kompas"
      sx={{
        backgroundColor: "#d8d8d8",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          maxWidth: "1440px",
          width: "100%",
          alignItems: "center",
          flexDirection: "column",
          position: "relative",
          marginBottom: "104px",
          zIndex: 3,
        }}
      >
        <img
          className={styles.element}
          style={{
            position: "absolute",
            top: 170,
            right: 0,
            zIndex: 1,
          }}
          src={require("../../assets/elements4.png")}
          alt="intro_floating_icons"
        />
        <motion.div
          ref={ref}
          initial={{ opacity: 0, y: 50 }}
          animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
          transition={{ duration: 0.7 }}
          style={{ zIndex: 3 }}
        >
          <Typography
            sx={{
              fontFamily: "VisbyCF-Medium",
              fontSize: 22,
              lineHeight: "28px",
              color: "#1b144a",
              textAlign: "center",
              wdith: "100%",
              marginTop: "70px",
              zIndex: 2,
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            ZÍSKAJ PRÁCU
          </Typography>
          <Typography
            sx={{
              fontFamily: "VisbyCF-Medium",
              fontSize: 38,
              color: "#1b144a",
              textAlign: "center",
              marginTop: "15px",
              zIndex: 2,
            }}
          >
            PROGRAM{" "}
            <span style={{ fontFamily: "VisbyCF-ExtraBold" }}>Zručnosti pre trh práce pre nezamestnaných</span>
          </Typography>
          <Typography
            sx={{
              fontFamily: "VisbyCF-Medium",
              fontSize: 22,
              lineHeight: "28px",
              color: "#1b144a",
              textAlign: "center",
              wdith: "100%",
              marginTop: "4px",
              zIndex: 2,
              paddingLeft: "10px",
              paddingRight: "10px",
            }}
          >
            Získaj naše{" "}
            <span style={{ fontFamily: "VisbyCF-ExtraBold" }}>
              školenie zadarmo
            </span>{" "}
            cez program Zručnosti pre trh práce a začni svoju novú kariéru v IT.
          </Typography>
        </motion.div>
        <motion.div
          ref={products}
          initial={{ opacity: 0, y: 50 }}
          animate={
            inViewProducts ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }
          }
          transition={{ duration: 0.7 }}
          style={{ zIndex: 3 }}
        >
          <Box
            sx={{
              marginTop: "78px",
              width: "100%",
              minHeight: "604px",
              display: "flex",
              flexDirection: { lg: "row", xs: "column" },
              alignItems: "start",
              zIndex: 2,
            }}
          >
            <Grid
              container
              sx={{
                display: "flex",
                justifyContent: "center",
                marginLeft: { lg: "120px", xs: "0px" },
                gap: "10px",
                width: { lg: "403px", xs: "100%" },
                paddingLeft: { xs: "10px", md: "0px" },
                paddingRight: { xs: "10px", md: "0px" },
              }}
            >
              <Box
                item
                sx={{
                  backgroundColor: "#1b144a",
                  height: isOpen ? "360px" : "100px",
                  transition: "height 300ms",
                  width: { sm: "403px", xs: "350px" },
                  borderRadius: "22px",
                }}
              >
                <Box sx={{ paddingTop: "26px", paddingBottom: "26px" }} id="kompas-about">
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      paddingRight: "18px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "VisbyCF-Medium",
                        fontSize: { sm: 28, xs: 22 },
                        lineHeight: { sm: "28px", xs: "22px" },
                        color: "#3fcaea",
                        marginLeft: "29px",
                        marginRight: "29px",
                      }}
                    >
                      O programe{" "}
                      <span style={{ fontFamily: "VisbyCF-ExtraBold" }}>
                      Zručnosti pre trh práce
                      </span>
                    </Typography>
                    <Link onClick={toggleCollapse}>
                      <img
                        src={isOpen ? blueMinus : bluePlus}
                        alt="blue_close"
                      />
                    </Link>
                  </Box>
                  <Collapse in={isOpen} timeout="auto" unmountOnExit>
                    <Typography
                      sx={{
                        fontFamily: "VisbyCF-Medium",
                        fontSize: { sm: 18, xs: 15 },
                        lineHeight: { sm: "22px" },
                        color: "white",
                        marginLeft: "29px",
                        marginRight: "29px",
                        marginTop: "21px",
                      }}
                    >
                      Zručnosti pre trh práce je program pre uchádzačov o zamestnanie
                      evidovaných úradom zameraný na rozvoj zručností pre budúce
                      uplatnenie na trhu práce. Účastníkom programu prepláca
                      úrad práce náklady na školenie v plnej výške. Ak si svoje
                      pracovné uplatnenie predstavuješ v IT, napríklad na
                      pozícii testera, sú pre teba vedomosti a zručnosti
                      obsiahnuté v sylaboch našich školení tie pravé.
                    </Typography>
                  </Collapse>
                </Box>
              </Box>
              <Box
                item
                sx={{
                  backgroundColor: "#1b144a",
                  height: isOpenAbout ? "800px" : "95px",
                  transition: "height 300ms",
                  width: { sm: "403px", xs: "350px" },
                  borderRadius: "22px",
                }}
              >
                <Box sx={{ paddingTop: "26px", paddingBottom: "26px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      paddingRight: "18px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "VisbyCF-Medium",
                        fontSize: { sm: 28, xs: 22 },
                        lineHeight: { sm: "28px", xs: "22px" },
                        color: "#f6961d",
                        marginLeft: "29px",
                        marginRight: "29px",
                      }}
                    >
                      Ako získať príspevok z ÚP
                    </Typography>
                    <Link onClick={toggleCollapseAbout}>
                      <img
                        src={isOpenAbout ? orangeMinus : orangePlus}
                        alt="blue_close"
                      />
                    </Link>
                  </Box>
                  <Collapse in={isOpenAbout} timeout={300} unmountOnExit>
                    <Typography
                      sx={{
                        fontFamily: "VisbyCF-Medium",
                        fontSize: { sm: 18, xs: 15 },
                        lineHeight: "22px",
                        color: "white",
                        marginLeft: "29px",
                        marginRight: "29px",
                        marginTop: "21px",
                      }}
                    >
                      {" "}
                      Ako získať príspevok na program „Zručnosti pre trh práce“ <br />
                      <br /> 1. Máš záujem o uplatnenie v IT? Vyber si kurz z našej ponuky. Ak sa nevieš rozhodnúť, radi ti s výberom pomôžeme, stačí nás kontaktovať. <br />
                      <br />
                      2. Ak si evidovaný na Úrade práce, vyplň tlačivo
                      {" "}
                      <Link
                        style={{
                          fontFamily: "VisbyCF-ExtraBold",
                          fontSize: 18,
                          lineHeight: "22px",
                          color: "white",
                        }}
                        to="/docs/ziadost_o_poskytnutie_prispevku_na_podporu_rekvalifikacie__uchadzaca_o_zamestnanie.docx"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Zručnosti pre trh práce
                      </Link>
        
                      . My vyplníme tlačivá za poskytovateľa a dodáme komplet dokumenty potrebné k schváleniu. Ak si si s niečím neistý/á radi ti pri vypĺňaní pomôžeme. <br />
                      <br />
                      3. Odovzdaj žiadosť na úrade práce minimálne 30 dní pred termínom školenia, ktorého sa chceš zúčastniť. <br />
                      <br />
                      4. O schválení žiadosti nás ihneď informuj a prihlás sa na vybraný termín a typ kurzu prostredníctvom nášho webu. Úrad s tebou uzavrie dohodu o poskytnutí príspevku na kompetenčný kurz a poskytne ti doklady, ktoré nám je potrebné odovzdať najneskôr v deň začatia kurzu. <br />
                      <br />
                      5. Uži si kurz a získaj cenné vedomosti pre svoje budúce uplatnenie.
                    </Typography>
                  </Collapse>
                </Box>
              </Box>
              <Box
                item
                sx={{
                  backgroundColor: "#1b144a",
                  height: isOpenNegative ? "546px" : "85px",
                  transition: "height 300ms",
                  width: { sm: "403px", xs: "350px" },
                  borderRadius: "22px",
                }}
              >
                <Box sx={{ paddingTop: "26px", paddingBottom: "26px" }}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      paddingRight: "18px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "VisbyCF-Medium",
                        fontSize: { sm: 28, xs: 22 },
                        lineHeight: { sm: "28px", xs: "22px" },
                        color: "#7b7cd5",
                        marginLeft: "39px",
                      }}
                    >
                      Dôvody neschválenia
                    </Typography>
                    <Link onClick={toggleCollapseNegative}>
                      <img
                        src={isOpenNegative ? redMinus : redPlus}
                        alt="blue_close"
                      />
                    </Link>
                  </Box>
                  <Collapse in={isOpenNegative} timeout="auto" unmountOnExit>
                    <Typography
                      sx={{
                        fontFamily: "VisbyCF-Medium",
                        fontSize: { sm: 18, xs: 15 },
                        lineHeight: "22px",
                        color: "white",
                        marginLeft: "29px",
                        marginRight: "29px",
                        marginTop: "21px",
                      }}
                    >
                      Pozor na najčastejšie dôvody neschválenia kurzu:
                      <br />
                      <br />
                      <span style={{ fontFamily: "VisbyCF-Bold" }}>
                        ● Nespĺňaš predpoklady definované úradom
                      </span>{" "}
                      - nie si evidovaný/á viac ako 3 mesiace. Počas trvania
                      kurzu ťa nesmú vyradiť z evidencie. <br />
                      <br />
                      <span style={{ fontFamily: "VisbyCF-Bold" }}>
                        ● V minulosti si podnikal/a a nedoložil/a si potrebné
                        potvrdenia.
                      </span>{" "}
                      Ak si mal/a založenú živnosť alebo s.r.o., potrebuješ na
                      Úrade práce doložiť potvrdenia o tom, že nemáš dlh voči
                      sociálnej, zdravotnej poisťovni, daňovému úradu a
                      Inšpektorátu práce. <br />
                      <br />
                      <span style={{ fontFamily: "VisbyCF-Bold" }}>
                        ● Nepodal/a si žiadosť včas.
                      </span>{" "}
                      Žiadosť je potrebné podať na Úrad práce včas, najneskôr 14
                      pracovných dní pred preferovaným termínom začiatku kurzu.
                    </Typography>
                  </Collapse>
                </Box>
              </Box>
              <Box
                item
                onClick={() => openForm()}
                sx={{
                  backgroundColor: "#f6961d",
                  height: "85px",
                  width: { sm: "403px", xs: "350px" },
                  borderRadius: "22px",
                  display: "flex",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <Link style={{ textDecoration: "none" }}>
                  <Box sx={{ paddingTop: "26px", paddingBottom: "26px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingRight: "18px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontFamily: "VisbyCF-Medium",
                          fontSize: 28,
                          lineHeight: "28px",
                          color: "#1b144a",
                          marginLeft: "29px",
                        }}
                      >
                        Pomôcť vybaviť{" "}
                        <span style={{ marginLeft: "25px" }}>{">>>"}</span>
                      </Typography>
                    </Box>
                  </Box>
                </Link>
              </Box>
            </Grid>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  marginRight: { lg: "8.61vw", xs: "0px" },
                  width: "auto",
                  display: { xs: "flex", lg: "block" },
                  flexDirection: "column",
                  justifyContent: "center",
                  alignContent: "center",
                  maxWidth: { xs: "600px", lg: "722px" },
                  marginLeft: "15px",
                  marginRight: { lg: "90px", xs: "15px" },
                  paddingBottom: "10px",
                  paddingTop: { xs: "10px", lg: "0px" },
                }}
              >
                <img
                  style={{
                    width: "100%",
                    height: "auto",
                    maxWidth: "100%",
                    maxHeight: "100%",
                  }}
                  src={require("../../assets/students-kompas.png")}
                  alt="students"
                />
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: { sm: "row", xs: "column" },
                    justifyContent: { sm: "end", xs: "center" },
                    alignItems: { sm: "unset", xs: "center" },
                    gap: { sm: "0px", xs: "10px" },
                    marginTop: "59px",
                  }}
                >
                  <Button
                    component="a"
                    href="/docs/ziadost_o_poskytnutie_prispevku_na_podporu_rekvalifikacie__uchadzaca_o_zamestnanie.docx"
                    target="_blank"
                    sx={{
                      borderRadius: "28px",
                      border: "2px solid",
                      borderColor: "#1b144a",
                      fontFamily: "VisbyCF-Bold",
                      fontSize: 16,
                      cursor: "pointer",
                      color: "#1b144a",
                      height: "41px",
                      width: "223px",
                      textTransform: "initial",
                      position: "relative",
                      // marginRight: "12px",
                      "&:hover": {
                        backgroundColor: "#1b144a",
                        color: "white",
                      },
                    }}
                  >
                    Žiadosť o príspevok
                    <img
                      style={{
                        // marginLeft: "10px",
                        position: "absolute",
                        width: "150px",
                        rotate: "180deg",
                        right: -45,
                        marginTop: "10px",
                      }}
                      src={arrow}
                      alt="blue_download"
                    />
                    <Box sx={{ width: "30px" }}></Box>
                  </Button>
                  <Button
                    component="a"
                    href="https://www.upsvr.gov.sk/sluzby-zamestnanosti/nastroje-aktivnych-opatreni-na-trhu-prace/narodny-projekt-zrucnosti-pre-trh-prace/poskytovanie-prispevkov-na-podporu-rekvalifikacie-uchadzacov-o-zamestnanie.html?page_id=1346520"
                    target="_blank"
                    sx={{
                      borderRadius: "28px",
                      border: "2px solid",
                      borderColor: "#1b144a",
                      fontFamily: "VisbyCF-Bold",
                      fontSize: 16,
                      marginLeft:"16px",
                      cursor: "pointer",
                      color: "#1b144a",
                      height: "41px",
                      width: "223px",
                      textTransform: "initial",
                      position: "relative",
                      // marginRight: "12px",
                      "&:hover": {
                        backgroundColor: "#1b144a",
                        color: "white",
                      },
                    }}
                  >
                    VIAC INFORMÁCIÍ
                    <img
                      style={{
                        // marginLeft: "10px",
                        position: "absolute",
                        width: "150px",
                        rotate: "180deg",
                        right: -45,
                        marginTop: "10px",
                      }}
                      src={arrow}
                      alt="blue_download"
                    />
                    <Box sx={{ width: "30px" }}></Box>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </motion.div>
      </Box>
    </Box>
  );
};

export default Kompas;
