import React, { useEffect, useState, useRef } from "react";
import { Box } from "@mui/material";
import Header from "../../components/global/Header";
import Intro from "../../components/academy/Intro";
import Lectors from "../../components/Lectors";
import Newsletter from "../../components/Newsletter";
import Workwithus from "../../components/academy/Workwithus";
import Vision from "../../components/academy/Vision";
import Loopingpromo from "../../components/global/Loopingpromo";
import Stats from "../../components/academy/Stats";
import AdoptSkill from "../../components/academy/AdoptSkill";
import Products from "../../components/global/Products";
import Footer from "../../components/global/Footer";
import Istqb from "../../components/global/Istqb";
import Testimonials from "../../components/global/Testimonials";
import TeamBanner from "../../components/global/TeamBanner";
import AboutUs from "../../components/academy/AboutUs";
import ScrollToTopButton from "../../components/global/ScrollToTop";
import FormKontaktGlobal from "../../components/global/FormKontaktGlobal";
import FormNewsletter from "../../components/global/FormNewsletter";
import { useLocation } from "react-router-dom";
import ButtonFinancovanie from "../../components/global/ButtonFinancovanie";
import PromoPopUp from "../../components/global/PromoPopUp";

const Academy = () => {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);
  const background = useRef(null);

  // Courses lists
  const courses = [
    {
        name: "ISTQB Certifikovaný tester základná úroveň (CTFL)",
        id: 1,
        description:
        "Akreditované ISTQB školenie pokrýva základy testovania softvéru, aplikovateľné na všetky priemyselné odvetvia, v ktorých sa vyskytuje vývoj a implementácia softvéru.",
        showDuration: false,
        showPrice: true,
        istqb: true,
        isPractice: false
    },
    {
        name: "Praktická stáž",
        id: 13,
        description:
          "Praktická stáž u IT klienta na reálnych projektoch. Dostupné pre absolventov sady kurzov Akadémie AAS.",
        showDuration: true,
        showPrice: false,
        istqb: false,
        isPractice: true
    },
    {
        name: "SQL základy 1-dňový kurz",
        id: 7,
        description:
        "Školenie SQL základy je určené pre používateľov začiatočníkov, ktorí sa chcú naučiť pracovať s SQL databázami. Účastníci sa zoznámia so všeobecnými princípmi a terminológiou relačných databáz.",
        showDuration: true,
        showPrice: true,
        istqb: false,
        isPractice: false
    },
  ];

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    // promo pop-up handling
    const usePopUp = false; // true to activate popup
    const [showPromoPopUp, setShowPromoPopUp] = useState(false);

  const [openContactForm, setContactForm] = useState(false);
  const handleClick = (e) => {
    if (e.target.id === "bg") {
        setContactForm(false);
        setShowPromoPopUp(false);
    }
  }

  // newsletter handling
  const backgroundNews = useRef(null);
  const [openNewsForm, setNewsForm] = useState(false);
  const handleClickNews = (e) => {
    if (e.target.id === "bgnews") setNewsForm(false);
  };

  // width handling

  useEffect(() => {
      const hasVisited = sessionStorage.getItem('hasVisited');

      if (!hasVisited && usePopUp) {
          setTimeout(function () {
              setShowPromoPopUp(true);
          }, 2000);

          sessionStorage.setItem('hasVisited', 'true');
      }


    // Update the window width when the component mounts
    setWindowWidth(window.innerWidth);

    // Add event listener to update the window width on resize
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const threshold = 200; // Adjust this value as needed

      if (scrollPosition > threshold) {
        setShowContent(true);
      } else {
        setShowContent(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function renderPopupWindow() {
      if (openContactForm) {
          return <FormKontaktGlobal setContactForm={setContactForm} />
      } else if (showPromoPopUp) {
          return <PromoPopUp setShowPromoPopUp={setShowPromoPopUp} />
      } else return;
  }

  return (
    <Box>
      {showContent && windowWidth > 1100 && <ButtonFinancovanie />}
      <Header />
      {/* intro */}
      <Intro />
      {/* Scroll to top */}
      <ScrollToTopButton />
      {/* looping promotion */}
      <Loopingpromo />
      {/* stats */}
      {windowWidth > 500 ? <Stats /> : null}
      {/* form contact */}
      {(openContactForm || showPromoPopUp) && (
        <Box
          onClick={handleClick}
          id="bg"
          ref={background}
          sx={{
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(128, 128, 128, 0.5)", // Grey color with 50% opacity
            overflow: "auto",
            zIndex: 50, // Set an appropriate z-index value
          }}
        >
            { renderPopupWindow() }
        </Box>
      )}

      {/* products */}
      <Products courses={courses} openContactForm={openContactForm} setContactForm={setContactForm} windowWidth={windowWidth} />
      {/* ISTQB */}
      <Istqb />
      {windowWidth <= 500 ? <Stats /> : null}
      {/* ADOPT ANOTHER SKILL */}
      <AdoptSkill />
      {/* accredited and skilled */}
      <Testimonials />
      {/* our team banner */}
      <TeamBanner />
      {/* lectors */}
      <Box
        sx={{
          // height: "647px",
          backgroundColor: "#1b144a",
          display: "flex",
          // alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Lectors itemsPerPage={1}></Lectors>
      </Box>

      {/* about us */}
      <AboutUs setContactForm={setContactForm} />
      {/* our vision */}
      <Vision />
      {/* work with us */}
      <Workwithus />
      {/* newsletter subscription */}
      <Newsletter setNewsForm={setNewsForm} />
      {/* form newsletter */}
      {openNewsForm && (
        <Box
          onClick={handleClickNews}
          id="bgnews"
          ref={backgroundNews}
          sx={{
            position: "fixed",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(128, 128, 128, 0.5)", // Grey color with 50% opacity
            overflow: "auto",
            zIndex: 50, // Set an appropriate z-index value
          }}
        >
          <FormNewsletter setNewsForm={setNewsForm} />
        </Box>
      )}
      <Footer setContactForm={setContactForm} />
    </Box>
  );
};

export default Academy;
