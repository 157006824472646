import React, { useState, useEffect, useRef } from "react";
import { Box, Typography, Button, Collapse } from "@mui/material";
import specializedCourses from "../../data/specializedCourses";
import lectors from "../../data/lectors";
import Robo from "../../assets/profile/robo.png";
import Beata from "../../assets/profile/beata.png";
import Martin from "../../assets/profile/martin.png";
import Tomas from "../../assets/profile/tomas.png";
import FormCourse from "../global/FormCourse";
import FormKontaktGlobal from "../global/FormKontaktGlobal";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import icon from "../../assets/skolenie_ikona_orangeyellow_stroke_rozklik.svg";
import arrow from "../../assets/arrow_modra.svg";
import { Link } from "react-router-dom";

const Description = ({
  courseId,
  isIstqb = false,
  isFormOpen,
  handleFormOpen,
  isSelectedDate,
  price,
  terms,
  courseName,
  duration,
  certification,
}) => {
  const [dates, setDates] = useState([]);

  useEffect(() => {
    if (terms) {
      let startDates = [];
      for (let i = 0; i < terms.length; i++) {
        startDates.push(terms[i].date_from);
      }
      setDates(startDates);
    }
  }, [terms]);

  useEffect(() => {
    if (isFormOpen) {
      document.body.style.overflow = "hidden";
    }

    if (!isFormOpen) {
      document.body.style.overflow = "visible";
    }
  });
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenFor, setIsOpenFor] = useState(false);
  const course = specializedCourses.find((obj) => obj.id == courseId);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  const toggleCollapseFor = () => {
    setIsOpenFor(!isOpenFor);
  };

  const returnPhoto = (id) => {
    var image;
    id = Number(id);
    switch (id) {
      case 1:
        image = Robo;
        break;
      case 2:
        image = Beata;
        break;
      case 3:
        image = Martin;
        break;
      case 6:
        image = Tomas;
    }
    return image;
  };

  const [ref, inView] = useInView({
    triggerOnce: true, // Only trigger the animation once when scrolled into view
  });
  const [products, inViewProducts] = useInView({
    triggerOnce: true, // Only trigger the animation once when scrolled into view
  });

  // close on click on bg
  const background = useRef(null);
  const backgroundTop = useRef(null);
  const handleClick = (e) => {
    if (e.target.id === "bg" || e.target.id === "bgTop") handleFormOpen();
  };

  return (
    <Box
      sx={{
        backgroundColor: "#d8d8d8",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        position: "relative",
        overflow: "hidden",
      }}
    >
      {/* purple bar */}
      <Box
        sx={{
          width: "100%",
          backgroundColor: "#7b7cd5",
          position: "relative",
          height: "91px",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <img
          style={{ position: "absolute", width: "226px", top: -45 }}
          src={icon}
          alt="student_hat"
        />
      </Box>
      {/* detail */}
      {/* <motion.div
        ref={ref}
        initial={{ opacity: 0, y: 50 }}
        animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: 0.7 }}
      > */}
      <Box
        sx={{
          maxWidth: "1440px",
          width: "100%",
          display: "flex",
          justifyContent: "center",
          position: "relative",
        }}
      >
        {/* TODO!!!! */}
        {/* <img
          className={styles.element}
          style={{
            position: "absolute",
            top: 100,
            zIndex: 1,
          }}
          src={require("../../assets/elements2.png")}
          alt="academy_logo"
        /> */}
        <Box
          sx={{
            display: "flex",
            marginLeft: { md: "9vw", xs: "20px" },
            marginRight: { md: "9vw", xs: "20px" },
            marginTop: "100px",
            justifyContent: "space-between",
            width: "100%",
            zIndex: 2,
            flexDirection: { md: "row", xs: "column" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              maxWidth: { md: "50%", xs: "unset" },
            }}
          >
            <Typography
              sx={{
                color: "#1d1a56",
                fontFamily: "VisbyCF-Bold",
                fontSize: "33px",
                lineHeight: "39px",
                // textAlign: { md: "start", xs: "center" },
                marginRight: "50px",
              }}
            >
              {courseName}
            </Typography>
            <Typography
              sx={{
                color: "#1d1a56",
                fontFamily: "VisbyCF-Medium",
                fontSize: "38px",
                lineHeight: "44px",
                textAlign: "start",
                marginBottom: "15px",
              }}
            >
              {duration}
            </Typography>
            <Box
              sx={{
                display: "flex",
                maxWidth: "700px",
                flexWrap: "wrap",
                justifyContent: "start",
                marginBottom: "15px",
              }}
            >
              {course.tags.map((tag, index) => {
                return (
                  <Typography
                    key={index}
                    sx={{
                      border: "2px solid",
                      borderColor: "#7b7cd5",
                      borderRadius: "16px",
                      color: "#1d1a56",
                      fontFamily: "VisbyCF-Medium",
                      fontSize: { md: 14, xs: 8 },
                      lineHeight: "29px",
                      paddingLeft: "7px",
                      paddingRight: "9px",
                      minHeight: "31px",
                      marginRight: "2px",
                      marginBottom: "6px",
                      display: "flex",
                      textAlign: "center",
                    }}
                  >
                    {tag}
                  </Typography>
                );
              })}
            </Box>
          </Box>
          <Box
            sx={{
              maxWidth: { md: "50%", xs: "undefined" },
              width: "100%",
              justifyContent: "space-between",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: { md: "center", xs: "center" },
                marginLeft: { md: "50px", xs: "0" },
              }}
            >
              {course.lectors.map((id, index) => {
                id = id - 1;
                return (
                  <Link
                    to={`/skolenia?lector=${id}#lectors`}
                    style={{ textDecoration: "none" }}
                  >
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        marginBottom: "17px",
                        minWidth: "205px",
                      }}
                    >
                      {lectors[id].id == 1 ||
                      lectors[id].id == 2 ||
                      lectors[id].id == 3 ? (
                        <Box
                          sx={{
                            borderRadius: "50%",
                            backgroundImage: `url(${returnPhoto(
                              lectors[id].id
                            )})`,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center",
                            width: "80px",
                            height: "80px",
                            display: "flex",
                            position: "relative",
                            alignItems: "center",
                          }}
                        ></Box>
                      ) : null}
                      {lectors[id].id == 6 ? (
                        <Box
                          sx={{
                            borderRadius: "50%",
                            width: "80px",
                            height: "80px",
                            display: "flex",
                            position: "relative",
                            alignItems: "center",
                            backgroundColor: "#5a5ccc",
                            overflow: "hidden",
                          }}
                        >
                          <img
                            style={{
                              position: "absolute",
                              bottom: -3,
                              right: 7,
                              borderRadius: "50%",
                              width: "70px",
                              height: "70px",
                            }}
                            src={require("../../assets/profile/tomas.png")}
                            alt="tomas"
                          />
                        </Box>
                      ) : null}
                      {lectors[id].id == 5 ? (
                        <Box
                          sx={{
                            borderRadius: "50%",
                            width: "80px",
                            height: "80px",
                            display: "flex",
                            position: "relative",
                            alignItems: "center",
                            backgroundColor: "#5a5ccc",
                            overflow: "hidden",
                          }}
                        >
                          <img
                            style={{
                              position: "absolute",
                              bottom: -10,
                              borderRadius: "50%",
                              width: "80px",
                              height: "80px",
                            }}
                            src={require("../../assets/profile/daniel.png")}
                            alt="daniel"
                          />
                        </Box>
                      ) : null}

                      {lectors[id].id == 4 ? (
                        <Box
                          sx={{
                            borderRadius: "50%",
                            width: "80px",
                            height: "80px",
                            display: "flex",
                            position: "relative",
                            alignItems: "center",
                            backgroundColor: "#5a5ccc",
                            overflow: "hidden",
                          }}
                        >
                          <img
                            style={{
                              position: "absolute",
                              bottom: -5,
                              left: 5,
                              borderRadius: "50%",
                              width: "64px",
                              height: "80px",
                            }}
                          />
                        </Box>
                      ) : null}

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "14px",
                          marginRight: "14px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontFamily: "VisbyCF-Medium",
                            fontSize: 16,
                            color: "#1d1a56",
                            lineHeight: "19px",
                          }}
                        >
                          {lectors[id].name}
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: "VisbyCF-Medium",
                            fontSize: 11,
                            lineHeight: "19px",
                            color: "#1d1a56",
                          }}
                        >
                          {lectors[id].role}
                        </Typography>
                      </Box>
                    </Box>
                  </Link>
                );
              })}
            </Box>
            {/* button */}
            <Box
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "right",
                alignItems: "end",
              }}
            >
              {!course.showPrice ? (
                <Button
                  onClick={handleFormOpen}
                  sx={{
                    borderRadius: "28px",
                    backgroundColor: "#eba62c",
                    fontFamily: "VisbyCF-Bold",
                    fontSize: 25,
                    cursor: "pointer",
                    color: "white",
                    height: "44px",
                    width: "185px",
                    textTransform: "initial",
                    "&:hover": {
                      backgroundColor: "#3fcaea",
                    },
                  }}
                >
                  <span style={{ fontFamily: "VisbyCF-Medium", fontSize: 18 }}>
                    {course.isPractice ? "Zadarmo" : "Na požiadanie"}
                  </span>
                </Button>
              ) : (price && terms?.length > 0) ? (
                <Button
                  onClick={handleFormOpen}
                  sx={{
                    borderRadius: "28px",
                    backgroundColor: "#eba62c",
                    fontFamily: "VisbyCF-Bold",
                    fontSize: 25,
                    cursor: "pointer",
                    color: "white",
                    height: "44px",
                    minWidth: "185px",
                    paddingLeft: "20px",
                    paddingRight: "20px",
                    textTransform: "initial",
                    "&:hover": {
                      backgroundColor: "#3fcaea",
                    },
                  }}
                >
                  <span
                    style={{
                      fontFamily: "VisbyCF-Medium",
                      fontSize: 18,
                      marginRight: "5px",
                    }}
                  >
                    Kúpiť za{" "}
                  </span>
                  {`${price} €`}
                  <span style={{ fontFamily: "VisbyCF-Medium", fontSize: 18 }}>
                    * bez DPH
                  </span>
                </Button>
              ) : null}
            </Box>
          </Box>
        </Box>
        {isFormOpen && (
          <Box
            onClick={handleClick}
            id="bgTop"
            ref={backgroundTop}
            sx={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(128, 128, 128, 0.5)", // Grey color with 50% opacity
              overflow: "auto",
              zIndex: 50, // Set an appropriate z-index value
              justifyContent: "center",
            }}
          >
            <Box
              onClick={handleClick}
              id="bg"
              ref={background}
              sx={{
                display: "flex",
                justifyContent: "center",
                paddingTop: "50px",
                paddingBottom: "50px",
              }}
            >
              {courseId != 13 ? (
                <FormCourse
                  datesArray={dates}
                  terms={terms}
                  onClose={handleFormOpen}
                  isSelectedDate={isSelectedDate}
                  courseName={courseName}
                  courseId={courseId}
                  certification={certification}
                />
              ) : (
                <FormKontaktGlobal
                  setContactForm={handleFormOpen}
                  contactMessage={"Mám záujem o praktickú stáž"}
                />
              )}
            </Box>
          </Box>
        )}
      </Box>
      {/* </motion.div> */}
      <motion.div
        ref={products}
        initial={{ opacity: 0, y: 50 }}
        animate={inViewProducts ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: 0.7 }}
      >
        <Box
          sx={{
            display: "flex",
            zIndex: 2,
            gap: "50px",
            marginTop: "50px",
            maxWidth: "1440px",
            marginBottom: "20px",
            flexDirection: { md: "row", xs: "column" },
          }}
        >
          <Box
            sx={{
              width: "100%",
              marginLeft: { md: "9vw", xs: "0px" },
            }}
          >
            {course.isPractice ? null : (
              <Typography
                sx={{
                  color: "#1b144a",
                  fontFamily: "VisbyCF-Bold",
                  fontSize: "28px",
                  lineHeight: "22px",
                  marginBottom: "5px",
                  textAlign: { md: "start", xs: "center" },
                }}
              >
                Prínos školenia
              </Typography>
            )}

            <Typography
              sx={{
                color: "#1b144a",
                fontFamily: "VisbyCF-Medium",
                fontSize: "18px",
                lineHeight: "22px",
                paddingRight: { md: "0", xs: "15px" },
                paddingLeft: { md: "0", xs: "15px" },
                textAlign: "start",
              }}
              dangerouslySetInnerHTML={{ __html: course.gain }}
            >
              {/* {course.gain} */}
            </Typography>
            {course.gainMore.length > 1 ? (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: { md: "end", xs: "end" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    cursor: "pointer",
                    position: "relative",
                    marginTop: "10px",
                    marginRight: { md: "0px", xs: "20px" },
                  }}
                  onClick={toggleCollapse}
                >
                  <img
                    style={{
                      rotate: isOpen ? "270deg" : "90deg",
                      position: "absolute",
                      width: "70px",
                      right: isOpen ? 50 : 25,
                      top: -22,
                    }}
                    src={arrow}
                    alt="arrow_more"
                  />
                  <Typography
                    sx={{
                      color: "#1b144a",
                      marginTop: "3px",
                      marginLeft: "6px",
                      fontFamily: "VisbyCF-Medium",
                      fontSize: "18px",
                      lineHeight: "22px",
                    }}
                  >
                    {!isOpen ? "Viac" : "Menej"}
                  </Typography>
                </Box>
              </Box>
            ) : null}

            <Collapse in={isOpen} timeout={300} unmountOnExit>
              <Typography
                sx={{
                  color: "#1b144a",
                  marginTop: "20px",
                  fontFamily: "VisbyCF-Medium",
                  fontSize: "18px",
                  lineHeight: "22px",
                  paddingRight: { md: "0", xs: "15px" },
                  paddingLeft: { md: "0", xs: "15px" },
                  textAlign: "start",
                }}
                dangerouslySetInnerHTML={{ __html: course.gainMore }}
              >
                {/* {course.gainMore} */}
              </Typography>
            </Collapse>
          </Box>
          <Box sx={{ width: "100%", marginRight: "9vw" }}>
            {course.isPractice ? null : (
              <Typography
                sx={{
                  color: "#1b144a",
                  fontFamily: "VisbyCF-Bold",
                  fontSize: "28px",
                  lineHeight: "22px",
                  marginBottom: "5px",
                  paddingRight: { md: "0", xs: "15px" },
                  paddingLeft: { md: "0", xs: "15px" },
                  textAlign: { md: "start", xs: "center" },
                }}
              >
                Pre koho je školenie určené
              </Typography>
            )}

            <Typography
              sx={{
                color: "#1b144a",
                fontFamily: "VisbyCF-Medium",
                fontSize: "18px",
                lineHeight: "22px",
                paddingRight: { md: "0", xs: "15px" },
                paddingLeft: { md: "0", xs: "15px" },
                textAlign: "start",
              }}
              dangerouslySetInnerHTML={{ __html: course.for }}
            >
              {/* {course.for} */}
            </Typography>
            {course.forMore ? (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: { md: "end", xs: "end" },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    marginTop: "10px",
                    cursor: "pointer",
                    position: "relative",
                    marginRight: { md: "0px", xs: "20px" },
                  }}
                  onClick={toggleCollapseFor}
                >
                  <img
                    style={{
                      rotate: isOpenFor ? "270deg" : "90deg",
                      position: "absolute",
                      width: "70px",
                      right: isOpenFor ? 50 : 25,
                      top: -22,
                    }}
                    src={arrow}
                    alt="arrow_more"
                  />
                  <Typography
                    sx={{
                      color: "#1b144a",
                      marginTop: "3px",
                      marginLeft: "6px",
                      fontFamily: "VisbyCF-Medium",
                      fontSize: "18px",
                      lineHeight: "22px",
                    }}
                  >
                    {!isOpenFor ? "Viac" : "Menej"}
                  </Typography>
                </Box>
              </Box>
            ) : null}

            <Collapse in={isOpenFor} timeout={300} unmountOnExit>
              <Typography
                sx={{
                  color: "#1b144a",
                  marginTop: "20px",
                  fontFamily: "VisbyCF-Medium",
                  fontSize: "18px",
                  lineHeight: "22px",
                  paddingRight: { md: "0", xs: "15px" },
                  paddingLeft: { md: "0", xs: "15px" },
                  textAlign: "start",
                }}
                dangerouslySetInnerHTML={{ __html: course.forMore }}
              >
                {/* {course.gainMore} */}
              </Typography>
            </Collapse>
          </Box>
        </Box>
      </motion.div>
    </Box>
  );
};

export default Description;
